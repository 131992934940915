import {Component, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements AfterViewInit {

  constructor() {
  }

  loadScripts() {
    const node = document.createElement('script');
    node.src = 'https://www.demowave.io/js/demo?id=f6673b65-2bf9-47c6-9ed7-5fa12958a009';
    node.type = 'text/javascript';
    node.async = false;
    document.getElementById('vinn-tutorial-content').appendChild(node);
  }

  ngAfterViewInit(): void {
    this.loadScripts();
  }
}
