import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {
  ListUserComponent,
  EditUserComponent,
  AddUserComponent,
  DashboardComponent,
  ViewUserComponent,
  UserComponent,
} from './components';
import {ErrorComponent} from './components/error/error.component';
import {MeComponent} from './components/me/me.component';
import {ListActivitiesComponent} from './components/activities/list/list-activities.component';
import {ManagementComponent} from './components/management/management.component';
import {
  ViewElectroDeviceInventoryComponent
} from './components/building/view/tabs/electro-device-inventory/view/view-electro-device-inventory.component';
import {EditBuildingComponent} from './components/building/edit/edit-building.component';
import {ListBuildingComponent} from './components/building/list/list-building.component';
import {AddBuildingComponent} from './components/building/add/add-building.component';
import {ViewBuildingComponent} from './components/building/view/view-building.component';
import {ListElectroDeviceComponent} from './components/electro-device/list/list-electro-device.component';
import {ListCompanyComponent} from './components/company/list/list-company.component';
import {AddCompanyComponent} from './components/company/add/add-company.component';
import {EditCompanyComponent} from './components/company/edit/edit-company.component';
import {ListReportComponent} from './components/report/list/list-report.component';
import {ViewTabletComponent} from './components/tablet/view/view-tablet.component';
import {TutorialComponent} from './components/tutorial/tutorial.component';
import {CompanyComponent} from './components/company/company.component';
import {BuildingComponent} from './components/building/building.component';
import {InventoryComponent} from './components/inventory/inventory.component';
import {ScannerSearchComponent} from './components/scanner-search/scanner-search.component';
import {TestingComponent} from './components/testing/testing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'me',
    component: MeComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Mein Profil'}
  },
  {
    path: 'table',
    component: ViewTabletComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },

  {
    path: 'inventories',
    component: InventoryComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'testings',
    component: TestingComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'management',
    component: ManagementComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'tutorial',
    component: TutorialComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: ''}
  },
  {
    path: 'companies',
    component: CompanyComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Firma'},
    children: [
      {
        path: '',
        component: ListCompanyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'create',
        component: AddCompanyComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Erstellen'}
      },
      {
        path: ':id/edit',
        component: EditCompanyComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Bearbeiten'}
      }
    ]
  },
  {
    path: 'reports',
    component: ListReportComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Berichte'},
    children: [
      {
        path: '',
        component: ListReportComponent,
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'buildings',
    component: BuildingComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'DGUV V3/4 Prüfungen'},
    children: [
      {
        path: '',
        component: ListBuildingComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Liste'}
      },
      {
        path: 'create',
        component: AddBuildingComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Erstellen'}
      },
      {
        path: ':id',
        component: ViewBuildingComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Übersicht'}
      },
      {
        path: ':id/electro-device-inventories/:inventoryId',
        component: ViewElectroDeviceInventoryComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Bestandverzeichnis'},
      },
      {
        path: ':id/edit',
        component: EditBuildingComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Bearbeiten'}
      },
    ]
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Users'},
    children: [
      {
        path: '',
        component: ListUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Liste'}
      },
      {
        path: 'create',
        component: AddUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Erstellen'}
      },
      {
        path: ':id',
        component: ViewUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'View'}
      },
      {
        path: ':id/edit',
        component: EditUserComponent,
        canActivate: [AuthGuard],
        data: {breadcrumb: 'Bearbeiten'}
      },
    ]
  },
  {
    path: 'electro-devices',
    component: ListElectroDeviceComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Elektrogeräte'}
  },
  {
    path: 'barcode-search',
    component: ScannerSearchComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Barcode Scan / Suche'}
  },
  {
    path: 'inventory',
    component: InventoryComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Inventar'}
  },
  {
    path: 'activities',
    component: ListActivitiesComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Activities'}
  },
  {
    path: 'logs',
    component: ListActivitiesComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'System Logs'}
  },
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
