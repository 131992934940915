import {Component, OnInit} from '@angular/core';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

  company: Company = {name: null, address: {}};
  submitEnabled = true;
  customerNumberIsValid = false;

  countryList = new Array<Country>();
  companyList = new Array<Company>();

  provinceList: Array<string> = [
    'Baden Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
  ];

  countryListLoading: boolean;
  companyListLoading: boolean;

  constructor(private companyService: CompanyService,
              private countryService: CountryService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private route: ActivatedRoute) {

  }

  ngOnInit() {

    this.loadCompanyList();

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    this.companyService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.company = response.data;
      this._validateCustomerNumber();
    });
  }

  onCreateFormSubmit(value) {

    this.submitEnabled = false;

    this.companyService.update(this.route.snapshot.paramMap.get('id'), value).subscribe(resp => {
      if (resp.status === 'success') {
        this.toastr.success('Entity has been successfully updated!');
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        // this.toastr.show(resp.message);
      }
      this.submitEnabled = true;
    });
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  loadCompanyList() {
    // this.companyListLoading = false;

    // if (this.isGranted('ROLE_SUPER_ADMIN')) {
    this.companyListLoading = true;
    this.companyService.getNameAllList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
    // }
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  _validateCustomerNumber() {
    this.customerNumberIsValid = this.company.customerNumber >= 10000 && this.company.customerNumber <= 69999;
  }

  validateCustomerNumber($event: KeyboardEvent) {
    this._validateCustomerNumber();
  }
}
