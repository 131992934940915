import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {User} from '../../models/user';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class MeService {

  endPoint = `/me`;

  constructor(private http: HttpClient) {
  }

  get(): Promise<DataResponse<User>> {
    return this.http.get<DataResponse<User>>(`${HelperService.getAPIUrl(this.endPoint)}`).toPromise();
  }

  update(value: any): Observable<DataListResponse<User>> {
    return this.http.put<DataListResponse<User>>(`${HelperService.getAPIUrl(this.endPoint)}`, value);
  }
}
