import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {Activity} from '../../models/activity';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';
@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  endPoint = `/activities`;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<Activity>> {
    return this.http.get<DataListResponse<Activity>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('size', page.size.toString())
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
    });
  }

  get(id): Observable<DataResponse<Activity>> {
    return this.http.get<DataResponse<Activity>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(activity: Activity): Observable<DataListResponse<Activity>> {
    return this.http.post<DataListResponse<Activity>>(HelperService.getAPIUrl(this.endPoint), activity);
  }

  update(id, value: any): Observable<DataListResponse<Activity>> {
    return this.http.put<DataListResponse<Activity>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`, value);
  }

  delete(id: number): Observable<DataListResponse<Activity>> {
    return this.http.delete<DataListResponse<Activity>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
