import {Component, OnInit} from '@angular/core';
import {Building} from '../../../models/building';
import {BuildingService} from '../../../services/building/building.service';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';
import {CountryService} from '../../../services/country/country.service';
import {Country} from '../../../models/country';
import {Currency} from '../../../models/currency';
import {CurrencyService} from '../../../services/currency/currency.service';
import {TimeZoneService} from '../../../services/time-zone/time-zone.service';
import {LocationService} from '../../../services/location/location.service';
import {TimeZone} from '../../../models/time-zone';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-building',
  templateUrl: './add-building.component.html',
  styleUrls: ['./add-building.component.css']
})
export class AddBuildingComponent implements OnInit {

  submitEnabled = true;

  building: Building = {
    name: null,
    address: {},
    company: {
      name: null,
      address: {}
    }
  };

  companyList = new Array<Company>();
  countryList = new Array<Country>();
  currencyList = new Array<Currency>();
  timeZoneList = new Array<TimeZone>();
  provinceList: Array<string> = [
    'Baden Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
  ];

  companyListLoading: boolean;
  countryListLoading: boolean;
  currencyListLoading: boolean;
  timeZoneListLoading: boolean;

  constructor(private buildingService: BuildingService,
              private companyService: CompanyService,
              private countryService: CountryService,
              private currencyService: CurrencyService,
              private timeZoneService: TimeZoneService,
              private toastr: ToastrService,
              private locationService: LocationService) {
  }

  ngOnInit() {

    this.loadCompanyList();

    this.countryService.getNameList().pipe(first()).subscribe(response => {
      this.countryList = response.data;
    });

    this.currencyService.getList().pipe(first()).subscribe(response => {
      this.currencyList = response.data;
    });

    this.timeZoneService.getList().pipe(first()).subscribe(response => {
      this.timeZoneList = response.data;
    });

  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  onFormSubmit(building: Building) {

    this.locationService.getLocation(this.building.address).subscribe(resp => {
      const location = resp[0];
      if (location) {
        this.building.address.longitude = location.lon;
        this.building.address.latitude = location.lat;
      }

      this.createBuilding(building);
    });

    this.submitEnabled = false;
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  createBuilding(building) {
    this.buildingService.create(building).subscribe(resp => {

      if (resp.status === 'success') {

        this.building = {
          name: null,
          address: {},
          company: {
            name: null,
            address: {}
          }
        };

        this.toastr.success('Building has been successfully created!');

      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }
}
