import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Building} from '../../../../../../models/building';
import {Table} from '../../../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../../../../models/page';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {BuildingService} from '../../../../../../services/building/building.service';
import {ElectroDeviceInventoryService} from '../../../../../../services/electro-device-inventory.service';
import {ElectroDeviceInventory} from '../../../../../../models/electro-device-inventory';
import {DataResponse} from '../../../../../../models/data-response';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {environment} from '../../../../../../../environments';
import {Image} from '../../../../../../models/image';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {AuthUser} from '../../../../../../models/auth-user';

@Component({
  selector: 'app-electro-device-inventory',
  templateUrl: './electro-device-inventory.component.html',
  styleUrls: ['./electro-device-inventory.component.css'],
  inputs: ['inventoryType']
})
export class ElectroDeviceInventoryComponent implements OnInit, OnChanges {

  @ViewChild('instanceForm', {static: true}) instanceForm;
  @Input() inventoryType: number;
  currentInventoryType = 0;

  pdfImporter = {
    fileNames: Array<string>()
  };

  curDate = new Date();
  createWaiting = false;
  columnMode = ColumnMode;
  building: Building = null;
  newObject = new ElectroDeviceInventory();
  imageList: any;
  buildingId: string = null;
  instanceId: string = null;

  createModalOptions = {
    title: 'Import geprüfte Geräteliste',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Erstellen',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
      this.newObject = new ElectroDeviceInventory();
      this.fileUploader.queue = [];
      this.creating = false;
    },
    preConfirm: () => {
      return new Promise((resolve) => {

        if (this.newObject.fileName != null) {
          this.createWaiting = true;
          this.create(resolve);
          this.createWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Prüfungsdatum', prop: 'testResultDate', width: 20},
      {name: 'Prüfungsnorm', prop: 'testNorm', width: 20},
    ],
    rowList: new Array<ElectroDeviceInventory>()
  };

  optionsInput: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Text_Csv],
    disableMultipart: false
  };

  optionPdfInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Application_Pdf],
    disableMultipart: false
  };

  ipAddressValidationMessage: string = null;
  private temp: any;
  importing = false;
  creating = false;


  user: AuthUser;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  uploadAllTriggered = false;

  imageOptionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png],
    disableMultipart: false
  };

  imageImageList: any;

  constructor(public fileUploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private electroDeviceInventoryService: ElectroDeviceInventoryService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private buildingService: BuildingService) {
  }

  importPdfModalOptions = {
    title: 'Import PDF Dateien',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
      this.fileUploader.queue = [];
      this.pdfUploader.queue = [];
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => !this.importing
  };

  summaryPdfModalOptions = {
    title: 'Import Zusammenfassung',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => false
  };

  defectSummaryPdfModalOptions = {
    title: 'Import Mängelliste',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => false
  };
  inventorySummaryPdfModalOptions = {
    title: 'Import Bestandsverzeichnis',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => false
  };

  imageUploadModalOptions = {
    title: 'Bild Hochladen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: true,
    focusCancel: true,
    allowOutsideClick: () => false,
    preConfirm: () => {
    },
    onClose: () => {
      this.galleryImages = [];
      this.fileUploader.removeAllFromQueue();
    },
    width: '1000px'
  };

  imageGalleryModalOptions = {
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    width: '700px',
  };

  electroDeviceInventoryId = null;

  ngOnChanges(changes: SimpleChanges): void {
    this.currentInventoryType = changes.inventoryType.currentValue;

    this.table.columnList = this.getColumnList(changes.inventoryType.currentValue);
    this.getPage(0);
  }

  ngOnInit() {

    this.buildingId = this.route.snapshot.paramMap.get('id');
    this.instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    this.buildingService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.building = response.data;
    });
    this.fileUploader.queue = [];
    this.fileUploader.onCancel$.subscribe(
      (data: FileItem) => {

      });

    this.fileUploader.onDropError$.subscribe(
      (err) => {
      });

    this.fileUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.fileUploader.onSuccess$.subscribe(
      (data: any) => {

        if (data.body.data.uploadType === 'inventory-item') {
          this.newObject.fileName = data.body.data.fileName;
        } else {

          this.galleryImages = data.body.data;

          this.table.rowList.forEach(value => {
            value.images.push(data.body.data.fileName);
          });
        }

        data.item.remove();
      }
    );

    this.fileUploader.onAddToQueue$.subscribe(
      () => {

      }
    );

    this.getPage(0);

  }

  delete(rowList: Array<ElectroDeviceInventory>) {

    const that = this;

    rowList.forEach(function (row) {
      that.electroDeviceInventoryService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  onActivate(event: any) {

  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.iso.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // this.dataTable.offset = 0;
  }

  upload(item: FileItem) {

    this.importing = true;

    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadUrl(this.route.snapshot.paramMap.get('id'))
    });

    this.importing = false;
  }


  getMediaResource(name: string) {
    return environment.apis.backend + '/media/uploads/' + name;
  }

  deleteImage(image: Image) {

  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const buildingId = this.route.snapshot.paramMap.get('id');

    this.electroDeviceInventoryService.getList(buildingId, this.table.page, this.currentInventoryType).subscribe(dataResponse => {

      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.temp = data;
      this.table.rowList = [...data];
      this.table.loading = false;

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message);
      }

    });
  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {
    this.creating = true;
    this.newObject.inventoryType = this.inventoryType;

    this.newObject.testNorm = this.getCurrentNormText(this.newObject.testNorm);

    this.electroDeviceInventoryService.create(this.newObject,
      this.route.snapshot.paramMap.get('id')
    ).subscribe((dataResponse: DataResponse<ElectroDeviceInventory>) => {

        this.createWaiting = false;
        this.newObject = new ElectroDeviceInventory();

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createWaiting = false;

        if (dataResponse.status === 'success') {
          this.newObject = new ElectroDeviceInventory();

          this.getPage(0);

        }
        resolve();
        this.creating = false;
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }


  onBeforeOpen($event: BeforeOpenEvent) {
  }

  view(resolve: (value?: (PromiseLike<any> | any)) => void) {

  }

  removeDocument() {
    this.newObject.fileName = null;
  }

  canCreate() {
    return (this.inventoryType >= 1 && this.inventoryType <= 3) && this.newObject.fileName && this.newObject.testResultDate;
  }

  getPDFLink(summaryFile) {
    return summaryFile ? `${environment.apis.backend}/media/uploads/summaries/${summaryFile}` : '';
  }

  getDefectPDFLink(summaryFile) {
    return summaryFile ? `${environment.apis.backend}/media/uploads/defect-summaries/${summaryFile}` : '';
  }

  getInventorySummaryPDFLink(summaryFile) {
    return summaryFile ? `${environment.apis.backend}/media/uploads/inventory-summaries/${summaryFile}` : '';
  }

  selectInventory(inventory: ElectroDeviceInventory) {
    this.electroDeviceInventoryService.registerCurrentInventory(inventory);
  }

  hasSummary(row: ElectroDeviceInventory) {
    return row.hasOwnProperty('summaryFile');
  }

  hasInventorySummary(row: ElectroDeviceInventory) {
    return row.hasOwnProperty('inventorySummaryFile');
  }

  hasDefectSummaryFile(row: ElectroDeviceInventory) {
    return row.hasOwnProperty('defectSummaryFile');
  }

  setImageForGallery(deviceInventory: ElectroDeviceInventory) {

    const self = this;
    this.galleryImages = [];

    deviceInventory.images.forEach(function (image) {
      const album: NgxGalleryImage = {
        url: `${environment.apis.backend}/media/uploads/electro-device-inventories/images/${deviceInventory.id}/${image}`,
        big: `${environment.apis.backend}/media/uploads/electro-device-inventories/images/${deviceInventory.id}/${image}`,
        small: `${environment.apis.backend}/media/uploads/electro-device-inventories/images/${deviceInventory.id}/${image}`,
        medium: `${environment.apis.backend}/media/uploads/electro-device-inventories/images/${deviceInventory.id}/${image}`,
        label: 'Bild ' + deviceInventory.id,
        description: '',
      };

      self.galleryImages.push(album);
    });
  }

  uploadImage(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadImageUrl(this.electroDeviceInventoryId)
    });
  }

  uploadImageAll() {
    this.uploadAllTriggered = true;
    this.fileUploader.uploadAll({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadImageUrl(this.electroDeviceInventoryId)
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.fileUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.fileUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.fileUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  setInventoryId(id) {
    this.galleryImages = [];
    this.fileUploader.removeAllFromQueue();
    this.electroDeviceInventoryId = id;
  }


  private getColumnList(inventoryType) {

    if (inventoryType === 1) {
      return [
        {name: 'VEDIN', prop: 'vedin'},
        {name: 'Gerätekategorie', prop: 'deviceType'},
        {name: 'Hersteller', prop: 'deviceManufaturer'},
        {name: 'Modell', prop: 'deviceModel'},
        {name: 'Seriennummer', prop: 'serialnumber'},
        {name: 'Barcode', prop: 'barcode'},
        {name: 'GTIN', prop: 'gtin'},
        {name: 'UVP', prop: 'uvp'},
        {name: 'Tags', prop: 'tags'},
        {name: 'Abgrenzung', prop: 'demarcation'},
        {name: 'Energie I', prop: 'freeText1'},
        {name: 'Energie II', prop: 'freeText2'},
        {name: 'Energie III', prop: 'freeText3'}
      ];
    }

    if (inventoryType === 2) {
      return [
        {name: 'VEDIN', prop: 'vedin'},
        {name: 'Gerätekategorie', prop: 'deviceType'},
        {name: 'Hersteller', prop: 'deviceManufaturer'},
        {name: 'Typ', prop: 'deviceModel'},
        {name: 'Seriennummer', prop: 'serialnumber'},
        {name: 'Inbetriebnahme', prop: 'installation'},
        {name: 'Ident-Nr.', prop: 'barcode'},
        {name: 'Standort', prop: 'location'},
        {name: 'Nächste Prüfung', prop: 'nextDeviceCheck'},
      ];
    }

    if (inventoryType === 3) {
      return [
        {name: 'VEDIN', prop: 'vedin'},
        {name: 'Gerätekategorie', prop: 'deviceType'},
        {name: 'Hersteller', prop: 'deviceManufaturer'},
        {name: 'Modell', prop: 'deviceModel'},
        {name: 'Seriennummer', prop: 'serialnumber'},
        {name: 'Barcode', prop: 'barcode'},
        {name: 'GTIN', prop: 'gtin'},
        {name: 'UVP', prop: 'uvp'},
        {name: 'Tags', prop: 'tags'},
        {name: 'Abgrenzung', prop: 'demarcation'},
        {name: 'Energie I', prop: 'freeText1'},
        {name: 'Energie II', prop: 'freeText2'},
        {name: 'Energie III', prop: 'freeText3'}
      ];
    }

    if (inventoryType === 4) {
      return [
        {name: 'VEDIN', prop: 'vedin'},
        {name: 'Gerätekategorie', prop: 'deviceType'},
        {name: 'Hersteller', prop: 'deviceManufaturer'},
        {name: 'Modell', prop: 'deviceModel'},
        {name: 'Seriennummer', prop: 'serialnumber'},
        {name: 'Barcode', prop: 'barcode'},
        {name: 'GTIN', prop: 'gtin'},
        {name: 'UVP', prop: 'uvp'},
        {name: 'Tags', prop: 'tags'},
        {name: 'Energie I', prop: 'freeText1'},
        {name: 'Energie II', prop: 'freeText2'},
        {name: 'Energie III', prop: 'freeText3'}
      ];
    }

    return [];
  }

  private getCurrentNormText(currentNorm: string) {

    if (currentNorm === '1') {
      return 'DIN EN 50699 (VDE 0702)';
    }

    if (currentNorm === '2') {
      return 'DIN EN 62353 (VDE 0751-1)';
    }

    if (currentNorm === '3') {
      return 'DIN VDE 105-100';
    }

    if (currentNorm === '4') {
      return 'DIN EN 50678 (VDE 0701)';
    }

    if (currentNorm === '5') {
      return 'ÖVE/ÖNORM E 8701-1';
    }

    return '';
  }
}
