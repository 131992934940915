import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nextDateType',
  pure: true
})
export class NextDateTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    switch (value) {
      case 1:
        return '3 Monate';
      case 2:
        return '6 Monate';
      case 3:
        return '1 Jahr';
      case 4:
        return '2 Jahre';
      case 5:
        return '3 Jahre';
      case 6:
        return '4 Jahre';

    }

    return value;
  }
}
