export class Country {
  id: number;
  name?: string;
  iso?: string;
  iso3?: string;
  phoneCode?: string;
  numberCode?: string;
  createdAt?: string;
  updatedAt?: string;
}
