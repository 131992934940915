import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {ElectroDeviceInventoryItem} from '../models/electro-device-inventory-item';
import {ReportSearchModel} from '../models/report-search-model';
import {DataResponse} from '../models/data-response';
import {Image} from '../models/image';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceInventoryItemService {

  private selectedInventoryItem: BehaviorSubject<ElectroDeviceInventoryItem> = null;


  endPoint = `/buildings`;

  constructor(private http: HttpClient) {
  }

  public getSelectedElectroInventoryItem(): Observable<ElectroDeviceInventoryItem> {
    return this.selectedInventoryItem.asObservable();
  }

  public setSelectedElectroInventoryItem(inventoryItem: ElectroDeviceInventoryItem): void {

    if (!this.selectedInventoryItem) {
      this.selectedInventoryItem = new BehaviorSubject<ElectroDeviceInventoryItem>(inventoryItem);
    }

    this.selectedInventoryItem.next(inventoryItem);
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceInventoryItem>> {
    return this.http.delete<DataListResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl(`/electro-device-inventory-items/${id}`)}`
    );
  }


  get(companyId, buildingId, barcode: string): Observable<DataResponse<ElectroDeviceInventoryItem>> {
    return this.http.get<DataResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl(`/electro-device-inventory-items/barcode-search`)}`,
      {
        params: new HttpParams()
          .set('barcode', barcode)
          .set('companyId', companyId)
          .set('buildingId', buildingId)
      }
    );
  }

  getList(buildingId: string, inventoryId: string, page: Page): Observable<DataListResponse<ElectroDeviceInventoryItem>> {
    return this.http.get<DataListResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventories/${inventoryId}/items`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
          .set('searchCriteria', page.searchCriteria)
      }
    );
  }

  search(searchModel: ReportSearchModel, page: Page): Observable<DataListResponse<ElectroDeviceInventoryItem>> {

    return this.http.get<DataListResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl('')}/electro-device-inventory-items/search`,
      {
        params: new HttpParams()
          .set('searchModel', JSON.stringify(searchModel))
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  barcodeCheck(searchModel: ReportSearchModel, page: Page): Observable<DataListResponse<ElectroDeviceInventoryItem>> {

    return this.http.get<DataListResponse<ElectroDeviceInventoryItem>>(
      `${HelperService.getAPIUrl('')}/electro-device-inventory-items/barcode-check`,
      {
        params: new HttpParams()
          .set('searchModel', JSON.stringify(searchModel))
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  getSearchInfoList(): Observable<any> {
    return this.http.get<any>(`${HelperService.getAPIUrl('')}/electro-device-inventory-items/search-info`, {
      params: new HttpParams()
    });
  }

  export(searchModel: ReportSearchModel): Observable<DataResponse<any>> {
    return this.http.get<DataResponse<any>>(
      `${HelperService.getAPIUrl('')}/report/export.csv`,
      {
        params: new HttpParams()
          .set('searchModel', JSON.stringify(searchModel))
      }
    );
  }

  uploadImageUrl(itemId: string) {
    return `${HelperService.getAPIUrl('')}/electro-device-inventory-items/${itemId}/images/upload`;
  }

  deleteImage(itemId: string, image: Image) {
    const url = `${HelperService.getAPIUrl('')}/${itemId}/images/${image.id}`;
    return this.http.delete<DataListResponse<Image>>(url);
  }

  sendMail(ticket: any) {
    return this.http.post(`${HelperService.getAPIUrl('')}/electro-device-inventory-items/${ticket.inventoryItemId}/ticket/send-mail`,
      ticket, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      });
  }

  exportInventory(electroDeviceInventoryId: string) {
    return this.http.get<DataResponse<any>>(
      `${HelperService.getAPIUrl('')}/electro-device-inventories/${electroDeviceInventoryId}/export`
    );
  }
}
