export class ReportSearchModel {
  vedin: any;
  companies = [];
  buildings = [];
  deviceTypes = [];
  deviceManufacturers = [];
  model = null;
  serialNumber = null;
  passed = true;
  size = 25;
  listType = 0; // Prüfungsliste = 0, Inventarliste = 1
  searchCriteria = 1;
}
