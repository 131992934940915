import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appInputMaxLength]'
})
export class InputMaxLengthDirective {

  @Input('appInputMaxLength') maxLength!: number;

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    if (inputElement.value.length > this.maxLength) {
      inputElement.value = inputElement.value.slice(0, this.maxLength);
    }
  }
}
