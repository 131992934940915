import {ElectroDeviceType} from './electro-device-type';
import {ElectroDeviceManufacturer} from './electro-device-manufacturer';

export class ElectroDevice {
  id: number;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  isDangerious: any;
  energyClass?: number;
  controlType?: string;
  protectionClass?: number;
  fileName?: string; // Local only, required for the upload
  uploadSchema?: string;
  type?: ElectroDeviceType;
  manufacturer?: ElectroDeviceManufacturer;
  eprelRegistrationNumber?: number;
  guaranteeDuration?: number;
  dangeriousDescription?: string;
  powerAnnual?: number;
  serialNumber?: string;
}
