import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from '../../models/page';
import {ToastrService} from 'ngx-toastr';
import {Activity} from '../../models/activity';
import {TimerObservable} from 'rxjs-compat/observable/TimerObservable';
import {DashboardService} from '../../services/dashboard.service';
import {Dashboard} from '../../models/dashboard';
// @ts-ignore
import {} from '@types/googlemaps';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  timer;
  timer2;
  page = new Page();
  activityList = new Array<Activity>();
  dashboard = new Dashboard();
  iconBase = '/assets/images/';

  constructor(/*private activityService: ActivityService,*/
              private toastr: ToastrService,
              private dashboardService: DashboardService,
              private authenticationService: AuthenticationService) {
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.getDashboard();
  }

  getDashboard() {

    this.timer = TimerObservable.create(0, 30000)
      .pipe()
      .subscribe(() => {
        this.dashboardService.get().subscribe(dataResponse => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message, 'Error');
          }

          this.dashboard = dataResponse.data;
        });
      });

  }


  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  formatNumber(number) {
    return new Intl.NumberFormat('de-DE').format(number);
  }
}
