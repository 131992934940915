import {Component, OnInit} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {ElectroDeviceInventoryService} from '../../../../../../../services/electro-device-inventory.service';

@Component({
  selector: 'app-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.css']
})
export class UploadPdfComponent implements OnInit {

  pdfImporter = {
    fileNames: Array<string>()
  };

  importing = false;

  optionPdfInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Application_Pdf],
    disableMultipart: false
  };
  totalUploading = 0;

  constructor(public pdfUploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private electroDeviceInventoryService: ElectroDeviceInventoryService) {
  }

  ngOnInit() {

    this.pdfImporter.fileNames = new Array<string>();

    this.pdfUploader.queue = [];
    this.pdfUploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.pdfUploader.onDropError$.subscribe(
      (err) => {
      });

    this.pdfUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.pdfUploader.onSuccess$.subscribe(
      (data: any) => {
        if (this.pdfUploader.queue.length > 0) {
          this.importing = false;
        }
      }
    );
  }

  uploadPdfFiles(item: FileItem) {

    this.importing = true;

    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadPdfUrl(this.route.snapshot.paramMap.get('id'))
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.pdfUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  removePDFDocuments() {
    this.pdfImporter.fileNames = new Array<string>();
    this.pdfUploader.queue = [];
  }

  uploadAll() {
    const that = this;
    this.importing = true;
    this.totalUploading = this.pdfUploader.queue.length;
    this.pdfUploader.queue.forEach(function (value) {
      that.uploadPdfFiles(value);
    });
  }
}
