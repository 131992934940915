import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {environment} from '../../../environments';
import {Image} from '../../models/image';
import {ActivatedRoute} from '@angular/router';
import {ElectroDeviceInventoryItemService} from '../../services/electro-device-inventory-item.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {

  @Input() instance = null;
  @ViewChild('instanceForm', {static: true}) instanceForm;

  uploadAllTriggered = false;

  optionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png],
    disableMultipart: false
  };

  imageList: any;

  constructor(public imageUploader: HttpClientUploadService,
              private route: ActivatedRoute,
              private electroDeviceInventoryItemService: ElectroDeviceInventoryItemService) {
  }

  ngOnInit() {
    this.init();
    this.imageUploader.queue = [];
    this.imageUploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.imageUploader.onDropError$.subscribe(
      (err) => {
      });

    this.imageUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.imageUploader.onSuccess$.subscribe(
      (data: any) => {
        this.instance.imageList = data.body.data;

        data.item.remove();
      }
    );

    this.imageUploader.onAddToQueue$.subscribe(
      () => {
        this.instanceForm.reset();
      }
    );
  }

  init() {
    this.instance = null;
  }

  upload(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryItemService.uploadImageUrl(this.route.snapshot.paramMap.get('id'))
    });
  }

  uploadAll() {
    this.uploadAllTriggered = true;
    this.imageUploader.uploadAll({
      method: 'POST',
      url: this.electroDeviceInventoryItemService.uploadImageUrl(this.route.snapshot.paramMap.get('id'))
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.imageUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.imageUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.imageUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }


  getMediaResource(name: string) {
    return environment.apis.backend + '/media/uploads/' + name;
  }

  deleteImage(image: Image) {
    const itemId = this.route.snapshot.paramMap.get('id');

    this.electroDeviceInventoryItemService.deleteImage(itemId, image).subscribe(dataResponse => {
      this.instance.imageList = dataResponse.data;
    });
  }
}
