import {Component, OnInit} from '@angular/core';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {

  company: Company = {name: null, address: {}};
  submitEnabled = true;

  countryList = new Array<Country>();
  companyList = new Array<Company>();

  customerNumberIsValid = false;
  companyListLoading: boolean;

  provinceList: Array<string> = [
    'Baden Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
  ];
  countryListLoading: boolean;

  constructor(private companyService: CompanyService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {

  }

  ngOnInit() {

    this.loadCompanyList();

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });
  }

  onCreateFormSubmit(value) {

    this.submitEnabled = false;

    this.companyService.create(value).subscribe(resp => {

      if (resp.status === 'success') {
        this.company = {name: null, address: {}};
        this.toastr.success('Firma wurde erfolgreich angelegt', 'Success');
      }

      if (resp.status === 'error') {
        this.toastr.error(resp.message, 'Error');
      }

      this.submitEnabled = true;
    });
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameAllList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  validateCustomerNumber($event: KeyboardEvent) {
    this.customerNumberIsValid = this.company.customerNumber >= 10000 && this.company.customerNumber <= 69999;
  }
}
