import {Injectable} from '@angular/core';
import {Company} from '../../models/company';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HelperService} from '../helper.service';
import {Page} from '../../models/page';
import {DataListResponse} from '../../models/data-list-response';
import {DataResponse} from '../../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  endPoint = `/companies`;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<Company>> {
    return this.http.get<DataListResponse<Company>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
    });
  }

  get(id): Observable<DataResponse<Company>> {
    return this.http.get<DataResponse<Company>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(company: Company): Observable<DataListResponse<Company>> {
    return this.http.post<DataListResponse<Company>>(HelperService.getAPIUrl(this.endPoint), company);
  }

  update(id, value: any): Observable<DataListResponse<Company>> {
    return this.http.put<DataListResponse<Company>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`, value);
  }

  delete(id: number): Observable<DataListResponse<Company>> {
    return this.http.delete<DataListResponse<Company>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  getNameList(): Observable<DataListResponse<Company>> {
    return this.http.get<DataListResponse<Company>>(`${HelperService.getAPIUrl(this.endPoint)}/query/list`, {});
  }

  getNameAllList(): Observable<DataListResponse<Company>> {
    return this.http.get<DataListResponse<Company>>(`${HelperService.getAPIUrl(this.endPoint)}/query/all-list`, {});
  }
}
