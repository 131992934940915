import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-electro-device-testing',
  templateUrl: './export-electro-device-testing.component.html',
  styleUrls: ['./export-electro-device-testing.component.css']
})
export class ExportElectroDeviceTestingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
