import {Address} from './address';

export class Company {
  id?: number;
  name: string;
  address: Address;
  vatNumber?: string;
  taxNumber?: string;
  createdAt?: string;
  updatedAt?: string;
  customerNumber?: number;
  parent?: Company;
  supportMailTo?: string;
  supportMailCc?: string;
}
