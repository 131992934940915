import {Component, OnInit, ViewChild} from '@angular/core';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Building} from '../../models/building';
import {AuthenticationService} from '../../services/authentication.service';
import {environment} from '../../../environments/environment.stage';
import {Table} from '../../models/table';
import {Page} from '../../models/page';
import {InventorySearchModel} from '../../models/inventory-search-model';
import {InventoryService} from '../../services/inventory.service';
import {Inventory} from '../../models/inventory';
import {DataResponse} from '../../models/data-response';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../models/company';
import {BuildingService} from '../../services/building/building.service';
import {CompanyService} from '../../services/company/company.service';
import {ElectroDeviceManufacturer} from '../../models/electro-device-manufacturer';
import {ElectroDeviceType} from '../../models/electro-device-type';
import {ElectroDeviceManufacturerService} from '../../services/electro-device-manufacturer.service';
import {ElectroDeviceTypeService} from '../../services/electro-device-type.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  @ViewChild('instanceForm', {static: true}) instanceForm;

  createWaiting = false;
  columnMode = ColumnMode;
  building: Building = null;
  newObject: Inventory;
  imageList: any;
  searchModel = new InventorySearchModel();

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    columnList: [
      {name: 'VEDIN', prop: 'vedin', type: 'text'},
      {name: 'Barcode', prop: 'barcode', type: 'text'},
      {name: 'Gerätekategorie', prop: 'deviceTypeStr', type: 'text'},
      {name: 'Hersteller', prop: 'deviceManufacturerStr', type: 'text'},
      {name: 'Modell', prop: 'deviceModel', type: 'text'},
      {name: 'Seriennummer', prop: 'serialNumber', type: 'text'},
      {name: 'Lieferdatum', prop: 'deliveryDate', type: 'date'},
      {name: 'Standort', prop: 'location', type: 'text'},
      {name: 'Einkaufspreis', prop: 'purchasePrice', type: 'text'},
      {name: 'Währung', prop: 'currency', type: 'text'},
      {name: 'Gewährleistung', prop: 'warranty', type: 'warranty'},
      {name: 'Ablauf Gewährleistung', prop: 'warrantyExpirationDate'},
      {name: 'VDE-Prüfung', prop: 'isVDEChecked', type: 'yesNo'},
      {name: 'Energie-Klasse', prop: 'energyClass', type: 'text'},
      {name: 'Gefährliche Geräte', prop: 'isDangerousDevice', type: 'yesNo'},
      {name: 'EPREL-Nummer', prop: 'eprelNumber', type: 'text'},
      {name: 'Existent', prop: 'existent', type: 'text'},
    ],
    page: new Page(),
    loading: false,
    rowList: new Array<Inventory>()
  };

  exporting = false;
  reportInfoReady = false;
  exportFile = null;

  createOneModalOptions = {
    title: 'Inventar hinzufügen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {

      return new Promise((resolve) => {
        if (this.newOneObject.vedin != null && this.newOneObject.barcode != null) {
          this.createOneWaiting = true;
          this.createOne(resolve);
          this.createOneWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.exporting
  };

  isImporting = false;
  searching = false;
  selectedInventory: Inventory;

  optionsInput: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Text_Csv],
    disableMultipart: false
  };

  optionPdfInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Application_Pdf],
    disableMultipart: false
  };

  inventoryBuildingList = new Array<Inventory>();

  ipAddressValidationMessage: string = null;
  private temp: any;
  importing = false;
  creating = false;


  uploadSchemas = [
    'Geschirrspüler',
    'Waschmaschinen',
    'Waschtrockner',
    'Fernsehgeräte, Monitore und sonstige Displays',
    'Kühl- und Gefriergeräte und Weinlagerschränke',
    'Kühl- und Gefriergeräte mit Direktverkaufsfunktion, Getränkekühler und Speiseeis-Gefriermaschinen',
    'Reifen',
    'Lichtquellen',
    'Luftkonditionierer',
    'Haushaltsbacköfen',
    'Dunstabzugshauben',
    'Haushaltswäschetrockner',
    'Einzelraumheizgeräte',
    'Gewerbliche Kühllagerschränke',
    'Wohnraumlüftungsanlagen',
    'Festbrennstoffkessel',
    'Verbundanlagen mit einem Festbrennstoffkessel',
    'Raumheizgeräte/Kombiheizgeräte',
    'Verbundanlagen mit Raumheizgeräten/Kombiheizgeräten',
    'Temperaturregler für Raumheizgeräte',
    'Solareinrichtungen für Raumheizgeräte',
    'Warmwasserbereiter',
    'Verbundanlagen mit Warmwasserbereitern',
    'Warmwasserspeicher für Warmwasserbereiter',
    'Solareinrichtungen für Warmwasserbereiter',
  ];
  sortList = [
    {id: 0, name: 'Modellkennung', type: 'asc', order: 'name'},
    {id: 1, name: 'Modellkennung', type: 'desc', order: 'name'},
    {id: 2, name: 'Name oder Handelsmarke des Lieferanten', type: 'asc', order: 'manufacturer.name'},
    {id: 3, name: 'Name oder Handelsmarke des Lieferanten', type: 'desc', order: 'manufacturer.name'},
    {id: 4, name: 'Gerättyp', type: 'asc', order: 'type.name'},
    {id: 5, name: 'Gerättyp', type: 'desc', order: 'type.name'},
    {id: 6, name: 'Energieeffizienzklasse', type: 'asc', order: 'energyClass'},
    {id: 7, name: 'Energieeffizienzklasse', type: 'desc', order: 'energyClass'}
  ];
  dangeriousList = [
    {id: 0, name: 'Alle'},
    {id: 1, name: 'Nur gefährliche Geräte'},
    {id: 2, name: 'Kein gefährliche Geräte'},
  ];
  totalPerPageList = [
    {number: 10, selected: true},
    {number: 25, selected: false},
    {number: 50, selected: false},
    {number: 100, selected: false},
  ];
  energyClassList = [
    {id: 0, name: 'Alle'},
    {id: 1, name: 'A'},
    {id: 2, name: 'B'},
    {id: 3, name: 'C'},
    {id: 4, name: 'D'},
    {id: 5, name: 'E'},
    {id: 6, name: 'F'},
    {id: 7, name: 'G'}
  ];
  protectionClassList = [
    {id: 0, name: 'Schutzklasse I'},
    {id: 1, name: 'Schutzklasse II'},
    {id: 2, name: 'Schutzklasse III'},
  ];
  createEnergyClassList = [
    {id: 0, name: 'Keine'},
    {id: 1, name: 'A'},
    {id: 2, name: 'B'},
    {id: 3, name: 'C'},
    {id: 4, name: 'D'},
    {id: 5, name: 'E'},
    {id: 6, name: 'F'},
    {id: 7, name: 'G'}
  ];

  cacheManufacturerList = new Array<ElectroDeviceManufacturer>();
  cacheTypeList = new Array<ElectroDeviceType>();

  createManufacturerList = new Array<ElectroDeviceManufacturer>();
  createManufacturerListLoading = false;

  createTypeList = new Array<ElectroDeviceType>();
  createTypeListLoading = false;

  createCacheTypeList = new Array<ElectroDeviceType>();
  createCacheManufacturerList = new Array<ElectroDeviceManufacturer>();

  newOneObject = new Inventory();
  editOneObject = new Inventory();

  yesNoList = [
    {id: 0, name: 'Nein', value: false},
    {id: 1, name: 'Ja', value: true},
  ];
  createOneWaiting = false;
  // CREATE

  manufacturerList = new Array<ElectroDeviceManufacturer>();
  manufacturerListLoading = false;

  typeList = new Array<ElectroDeviceType>();
  typeListLoading = false;

  singleEnergyClassList = ['Keine', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];

  existentList = ['Verloren', 'Verkauft', 'Verschrottet'];
  loadedInventory = null;

  editOneModalOptions = {
    title: 'Gerät bearbeiten',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Aktualisieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createOneWaiting = true;
        this.updateOne(resolve);
        this.createOneWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createOneWaiting
  };
  inventoriesLoading = false;

  constructor(public uploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private inventoryService: InventoryService,
              private buildingService: BuildingService,
              private companyService: CompanyService,
              private electroDeviceManufacturerService: ElectroDeviceManufacturerService,
              private electroDeviceTypeService: ElectroDeviceTypeService) {
  }

  companyListLoading = false;
  companyList: Array<Company> = [];
  buildingList: any;
  buildingCacheList = [];
  buildingListLoading = false;
  selectedBuildingId = null;
  createModalOptions = {
    title: 'Inventory importieren',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
      return new Promise((resolve) => {
        if (this.newObject.fileName != null) {
          this.createWaiting = true;
          this.create(resolve);
          this.createWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };
  selectedToDelete: Inventory;
  selectedToEdit: Inventory;

  ngOnInit() {

    this.loadCompanyList();
    this.loadBuildingList();

    this.uploader.queue = [];
    this.uploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.uploader.onDropError$.subscribe(
      (err) => {
      });

    this.uploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.uploader.onSuccess$.subscribe((data: any) => {
      if (data.body.data.uploadType === 'inventory') {
        this.newObject.fileName = data.body.data.fileName;
        data.item.remove();
      }
    });

    this.getTypeList();
    this.getManufacturerList();
    this.newObject = this.createNewInventory();
    this.newOneObject = this.createNewInventory();
    this.selectedToDelete = this.createNewInventory();
    this.selectedInventory = this.createNewInventory();
    this.getPage(0);
  }

  private getManufacturerList() {
    this.electroDeviceManufacturerService.getNameList().subscribe(value => {

      this.manufacturerList = [...value.data];
      this.createManufacturerList = [...value.data];

      this.manufacturerList.unshift({'id': 0, 'name': 'Alle'});
      this.cacheManufacturerList = this.manufacturerList;
    });
  }

  private getTypeList() {
    this.electroDeviceTypeService.getNameList().subscribe(value => {
      this.typeList = [...value.data];
      this.createTypeList = [...value.data];
      this.typeList.unshift({'id': 0, 'name': 'Alle'});
      this.cacheTypeList = this.typeList;
    });
  }

  loadBuildingList() {
    this.buildingListLoading = true;
    this.buildingService.getNameList().subscribe(resp => {
      this.buildingList = resp.data;
      this.buildingListLoading = false;
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onSearchCompanyChange($event: any) {
    this.newObject.companyId = $event;
    this.buildingCacheList = this.buildingList.filter((building: Building) => {
      return building.company.id === $event;
    });
  }

  onSearchBuildingChange($event: any) {
    this.newObject.buildingId = $event;
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  createNewInventory() {
    const inventory = new Inventory();
    inventory.buildingId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    return inventory;
  }

  removeDocument() {
    this.newObject.fileName = null;
  }

  canCreate() {
    return this.newObject.fileName;
  }

  upload(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.inventoryService.uploadUrl()
    });
  }

  createOne(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.newOneObject.buildingId = this.selectedBuildingId;

    this.inventoryService.createOne(this.newOneObject).subscribe((dataResponse: DataResponse<Inventory>) => {

        this.createWaiting = false;

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        if (dataResponse.status === 'success') {
          this.getPage(0);
          this.newOneObject = this.createNewInventory();
        }

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
      });
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.isImporting = true;

    this.inventoryService.create(this.newObject).subscribe((dataResponse: DataResponse<Inventory>) => {

        this.createWaiting = false;

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        if (dataResponse.status === 'success') {
          this.getPage(0);
          this.newObject = this.createNewInventory();
        }

        this.isImporting = false;
        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
        this.isImporting = false;
      });
  }

  delete(rowList: Array<Inventory>) {

    const that = this;

    rowList.forEach(function (row) {
      that.inventoryService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });
    });
  }

  onActivate(event: any) {

  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.loadInventory(this.selectedBuildingId, 0);
  }

  onDelete(rowList) {
    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.iso.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // this.dataTable.offset = 0;
  }

  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }
    this.table.rowList = new Array<Inventory>();
    this.inventoriesLoading = true;
    this.inventoryService.getList(this.selectedInventory.buildingId, this.table.page).subscribe(dataResponse => {
      const data = dataResponse.data;
      this.inventoryBuildingList = [...data];

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message);
      }
      this.inventoriesLoading = false;
    });
  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }

  view(resolve: (value?: (PromiseLike<any> | any)) => void) {

  }

  export(type: string) {

    this.exportFile = null;
    this.exporting = true;

    const self = this;

    if (type === 'csv') {
      setTimeout(function () {
        self.reportInfoReady = true;
      }, 2000, self);

      this.inventoryService.export().subscribe(resp => {
        this.exporting = false;
        this.exportFile = resp.data.fileName;
      });

    }
  }

  getExportFile() {
    return this.exportFile ? `${environment.apis.backend}/media/temp/inventories/${this.exportFile}` : 'no-file-found';
  }

  cancelExport() {
    this.swalTargets.cancelButton().click();
    this.exporting = false;
    this.exportFile = null;
  }

  parseTags(tags) {
    return tags.trim().split(',');
  }

  removeInventory(row: any) {
    this.inventoryService.delete(row.id).subscribe(resp => {
      if (resp.status === 'success') {
        this.table.rowList = this.table.rowList.filter(function (obj) {
          return obj.id !== row.id;
        });
        this.toastr.success(resp.message);
        this.table.selected = [];
      } else {
        this.toastr.error(resp.message);
      }
    });
  }

  closeInventoryView() {
    this.table.rowList = [...[]];
  }

  public loadInventory(buildingId, pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.selectedBuildingId = buildingId;

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.inventoryService.getInventoryList(buildingId, this.table.page).subscribe(dataResponse => {

      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.temp = data;
      this.table.rowList = [...data];
      this.table.loading = false;

      this.loadedInventory = data;

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message);
      }
    });
  }

  getColumnValue(column: any, row: Inventory) {

    if (column.type === 'array') {
      return row[column.prop] !== null ? row[column.prop].name : row[column.prop];
    }

    if (column.type === 'date') {

      const value = row[column.prop];

      if (value) {
        const date = new Date(value);
        const day = ('00' + date.getDate()).slice(-2);
        const month = ('00' + (date.getMonth() + 1)).slice(-2);
        const year = ('00' + date.getFullYear()).slice(-2);
        return `${day}.${month}.${year}`;
      }

      return row[column.prop];
    }

    if (column.type === 'yesNo') {
      return column.prop === 'true' ? 'Ja' : 'Nein';
    }

    if (column.type === 'warranty') {
      if (row[column.prop]) {
        return `${row[column.prop]} Monate`;
      }
    }

    return row[column.prop];
  }

  onCreateSearchDeviceTypeChange($event: any) {

    if ($event === 0) {
      this.createManufacturerList = this.createCacheManufacturerList;
      return;
    }

    this.createManufacturerList = this.cacheManufacturerList.filter((manufacturer: any) => {

      let result = false;

      if (!manufacturer.typeList) {
        return false;
      }

      manufacturer.typeList.forEach(function (value, index) {
        if (value.id === $event) {
          result = true;
          return false;
        }
      });

      return result;
    });

  }

  private updateOne(resolve: (value?: (PromiseLike<any> | any)) => void) {


    this.editOneObject.buildingId = this.selectedBuildingId;

    this.inventoryService.updateOne(this.editOneObject).subscribe((dataResponse: DataResponse<Inventory>) => {

        this.createOneWaiting = false;
        this.editOneObject = new Inventory();

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createOneWaiting = false;

        if (dataResponse.status === 'success') {
          this.editOneObject = new Inventory();
          this.loadInventory(this.selectedBuildingId, 0);
        }

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createOneWaiting = false;
      });
  }

  onOneSearchCompanyChange($event: any) {
    this.newOneObject.companyId = $event;
    this.newOneObject.buildingId = null;
    this.buildingCacheList = this.buildingList.filter((building: Building) => {
      return building.company.id === $event;
    });
  }

  onOneSearchBuildingChange($event: any) {
    this.newOneObject.buildingId = $event;
  }

  onOneEditSearchCompanyChange($event: any) {
    this.editOneObject.companyId = $event;
    this.editOneObject.buildingId = null;
    this.buildingCacheList = this.buildingList.filter((building: Building) => {
      return building.company.id === $event;
    });
  }

  onOneEditSearchBuildingChange($event: any) {
    this.editOneObject.buildingId = $event;
  }

  deleteItems(buildingId) {
    this.inventoryService.deleteByBuilding(buildingId).subscribe((data: any) => {
      this.getPage(0);
    });
  }
}
