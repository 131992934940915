import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {ElectroDeviceTesting} from '../models/electro-device-testing';
import {HelperService} from './helper.service';
import {Page} from '../models/page';
import {TestingSearchModel} from '../models/testing-search-model';
import {DataResponse} from '../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceTestingService {

  endPoint = `/electro-device-testings`;


  private _subject = new Subject<any>();

  newEvent(event) {
    this._subject.next(event);
  }

  get events$() {
    return this._subject.asObservable();
  }

  constructor(private http: HttpClient) {
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceTesting>> {
    return this.http.delete<DataListResponse<ElectroDeviceTesting>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${id}`
    );
  }

  getList(): Observable<DataListResponse<ElectroDeviceTesting>> {
    return this.http.get<DataListResponse<ElectroDeviceTesting>>(`${HelperService.getAPIUrl(this.endPoint)}`);
  }

  getTestingList(buildingId: number, page: Page): Observable<DataListResponse<ElectroDeviceTesting>> {
    return this.http.get<DataListResponse<ElectroDeviceTesting>>(
      `${HelperService.getAPIUrl(this.endPoint)}/devices`,
      {
        params: new HttpParams()
          .set('buildingId', buildingId.toString())
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
          .set('searchCriteria', page.searchCriteria)
      }
    );
  }


  search(searchModel: TestingSearchModel, page: Page): Observable<DataListResponse<ElectroDeviceTesting>> {

    return this.http.get<DataListResponse<ElectroDeviceTesting>>(
      `${HelperService.getAPIUrl(this.endPoint)}/search`,
      {
        params: new HttpParams()
          .set('searchModel', JSON.stringify(searchModel))
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  getSearchInfoList(): Observable<any> {
    return this.http.get<any>(`${HelperService.getAPIUrl(this.endPoint)}/search-info`, {
      params: new HttpParams()
    });
  }

  export(): Observable<DataResponse<any>> {
    return this.http.get<DataResponse<any>>(
      `${HelperService.getAPIUrl(this.endPoint)}/export`,
    );
  }

  uploadDeviceCSVUrl() {
    return `${HelperService.getAPIUrl(this.endPoint)}/upload-device-csv`;
  }

  create(testing: ElectroDeviceTesting) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}`, testing, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  setStatus(status: number, testing: ElectroDeviceTesting) {
    return this.http.put(`${HelperService.getAPIUrl(this.endPoint)}/${testing.id}/set-status`, testing, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: new HttpParams().set('status', status.toString())
    });
  }

  checkTesting(testing: ElectroDeviceTesting) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${testing.id}/check`, testing, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  createOne(testing: ElectroDeviceTesting) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/single`, testing, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  updateOne(testing: ElectroDeviceTesting) {
    return this.http.put(`${HelperService.getAPIUrl(this.endPoint)}/${testing.id}`, testing, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  upload(testingId: number, fileName: string) {
    return this.http.post(
      `${HelperService.getAPIUrl(this.endPoint)}/${testingId}/import-items`,
      {},
      {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        params: new HttpParams().set('fileName', fileName)
      });
  }
}
