import {Injectable} from '@angular/core';
import {environment} from '../../environments';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() {
  }

  static getAPIUrl(s?: string) {
    return s ? `${environment.apis.backend}/${s.replace(/^\/+/, '')}` : environment.apis.backend;
  }
}
