import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {User} from '../../../models/user';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {ToastrService} from 'ngx-toastr';
import {RoleType} from '../../../models/role-type';
import {UserType} from '../../../models/user-type';
import {UserTypeService} from '../../../services/user-type/user-type.service';
import {Locale} from '../../../models/locale';
import {ActivatedRoute} from '@angular/router';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {PermissionMapService} from '../../../services/role/permission-map.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  submitEnabled = true;

  user: User = {
    id: null,
    email: null,
    username: null,
    roleList: null,
    keycloakId: null,
    password: null,
    temporaryPassword: null,
    profile: {
      id: null,
      address: {
        id: null
      },
    },
    createdAt: null,
    updatedAt: null,
    locale: 'de',
    enabled: false,
    emailVerified: true,
    type: null,
    buildingList: [],
    permissionList: [],
  };

  companyList = new Array<Company>();
  companyListLoading: boolean;

  localeList = new Array<Locale>();

  countryList = new Array<Country>();
  countryListLoading: boolean;

  userTypeList = new Array<UserType>();
  userTypeListLoading: boolean;

  roleTypeList: Array<RoleType>;

  items: TreeviewItem[];
  values: string[];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: -1
  });

  buildingPermissions: TreeviewItem[];
  userPermissions: TreeviewItem[];

  constructor(private userService: UserService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private roleService: PermissionMapService,
              private userTypeService: UserTypeService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private companyService: CompanyService) {
  }

  ngOnInit() {

    this.loadCompanyList();

    this.localeList = [
      {
        iso: 'de',
        name: 'Deutsch'
      },
      {
        iso: 'en',
        name: 'English'
      }
    ];

    this.userService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {

      if (response.status === 'error') {
        this.toastr.error(response.message);
      }

      this.user = response.data.portalInfo;
      this.user.username = response.data.loginInfo.username;
      this.user.email = response.data.loginInfo.email;
      this.user.firstName = response.data.loginInfo.firstName;
      this.user.lastName = response.data.loginInfo.lastName;
      this.user.enabled = response.data.loginInfo.enabled;
      this.user.emailVerified = response.data.loginInfo.emailVerified;
      this.user.initial = response.data.loginInfo.initial;

      this.updatePermissions();
    });

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    this.userTypeService.getList().subscribe(response => {
      this.userTypeList = response.data;
    });
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onFormSubmit(value) {

    this.submitEnabled = false;

    value.buildingList = this.user.buildingList;
    value.permissionList = this.user.permissionList;

    this.userService.update(this.route.snapshot.paramMap.get('id'), value).subscribe(resp => {
      if (resp.status === 'success') {
        this.toastr.success('Benutzerdaten wurden erfolgreich aktualisiert');
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        // this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  onFilterChange(value: string) {

  }

  onBuildingPermissionSelectChange($event: any[]) {
    this.user.buildingList = $event;
  }
  onUserPermissionSelectChange($event: any[]) {
    this.user.permissionList = $event;
  }

  updatePermissions() {
    this.roleService.getUserRoles(this.user.id).then(value => {
      this.buildingPermissions = [new TreeviewItem(value.data.buildingList)];
      this.userPermissions = [new TreeviewItem(value.data.permissionList)];
    });
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }
}
