import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../models/page';
import {BuildingService} from '../../../services/building/building.service';
import {Building} from '../../../models/building';
import {ToastrService} from 'ngx-toastr';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {AuthenticationInterface, AuthenticationService} from '../../../services/authentication.service';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';
import {DataListResponse} from '../../../models/data-list-response';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-list-building',
  templateUrl: './list-building.component.html',
  styleUrls: ['./list-building.component.css']
})
export class ListBuildingComponent implements OnInit, OnDestroy, AuthenticationInterface {

  @ViewChild('buildingTable', {static: false}) tableDC: DatatableComponent;

  temp = [];
  calculated = [];
  pending = [];
  groups = [];
  editing = {};

  createWaiting = false;
  editWaiting = false;
  newObject = new Building();
  selectedBuilding = new Building();

  firstLoad = true;

  table: Table = {
    columnMode: ColumnMode.standard,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [],
    rowList: new Array<Building>()
  };

  createModalOptions = {
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    showConfirmButton: false,
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Erstellen',
    showLoaderOnConfirm: false,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  editModalOptions = {
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    showConfirmButton: false,
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Speichern',
    showLoaderOnConfirm: false,
    focusCancel: true,
    allowOutsideClick: () => !this.editWaiting
  };

  /// ADD

  submitEnabled = true;

  building: Building = {
    name: null,
    company: new Company(),
    address: {}
  };

  companyList = new Array<Company>();

  companyListLoading: boolean;

  constructor(public readonly swalTargets: SwalPartialTargets,
              private buildingService: BuildingService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService,
              private companyService: CompanyService) {

    this.table.page.pageNumber = 0;
    this.table.page.size = 50;
    this.table.loading = true;
  }

  ngOnInit(): void {
    this.loadCompanyList();
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  getPage(pageNumber) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    if (this.firstLoad) {
      this.table.page.sortColumn = 'internalNumber';
      this.table.page.sortOrder = 'ASC';
    }

    this.table.page.pageNumber = pageNumber;
    this.buildingService.getList(this.table.page).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.table.page = dataResponse.page;
      }

      const data = dataResponse.data;

      this.temp = [...data];
      this.table.rowList = data;
      this.table.loading = false;
      this.firstLoad = false;
    });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  deleteData(row) {

  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList) {
    this.delete(rowList);
  }

  delete(rowList: Array<Building>) {

    const that = this;

    rowList.forEach(function (row) {
      that.buildingService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.tableDC.offset = 0;
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null): boolean {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  toggleExpandGroup(group) {
    this.tableDC.groupHeader.toggleExpandGroup(group);
  }

  onDetailToggle(event) {
  }

  edit(row: Building) {
    this.selectedBuilding = row;
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.buildingService
      .create(this.newObject)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.createWaiting = false;

          this.displayMessage(dataResponse);
        },
        error => {
          this.createWaiting = false;
        });
  }

  private displayMessage(dataResponse: DataListResponse<Building>) {
    if (dataResponse.status === 'error') {
      this.toastr.error(dataResponse.message, 'Error');
    }

    if (dataResponse.page !== null) {
      this.table.page = dataResponse.page;
    }
  }

  // ADD


  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  onFormSubmit(building: Building) {

    this.createBuilding(building);
    this.submitEnabled = false;
  }

  onUpdateSubmit(building: Building) {

    this.buildingService.update(this.selectedBuilding.id, this.selectedBuilding)
      .subscribe(dataResponse => {

          this.editWaiting = false;
          this.submitEnabled = false;

          if (dataResponse.status === 'success') {

            this.toastr.success(`Erfolgreich gespeichert`);

            this.building = {
              name: null,
              address: {},
              company: {
                name: null,
                address: {}
              }
            };
            this.swalTargets.cancelButton().click();
          } else if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          } else {
            this.toastr.show(dataResponse.message);
          }
        },
        error => {
          this.editWaiting = false;
          this.submitEnabled = false;
        });
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  createBuilding(building) {
    this.buildingService.create(building).subscribe(resp => {

      if (resp.status === 'success') {

        this.toastr.success(`Gebäude mit der Name (${this.building.name}) wurde erfolgreich erstellt`);

        this.building = {
          name: null,
          address: {},
          company: {
            name: null,
            address: {}
          }
        };
        this.swalTargets.cancelButton().click();
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  setSelectedBuilding(row: any) {
    this.selectedBuilding = row;
  }

  searchRemote($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.getPage(0);
    }
  }
}
