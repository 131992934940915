import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Building} from '../../../models/building';
import {BuildingService} from '../../../services/building/building.service';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {Title} from '@angular/platform-browser';
import {BeforeOpenEvent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-view-building',
  templateUrl: './view-building.component.html',
  styleUrls: ['./view-building.component.css']
})
export class ViewBuildingComponent implements OnInit,  OnChanges {
  id: string;
  building: Building;
  selected = [];
  currentTab = 1;


  constructor(private route: ActivatedRoute,
              private buildingService: BuildingService,
              private authenticationService: AuthenticationService,
              private titleService: Title) {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.getBuildingView();
  }

  getBuildingView() {
    this.buildingService.get(this.route.snapshot.paramMap.get('id')).subscribe(dataResponse => {
      this.building = dataResponse.data;
      this.titleService.setTitle( 'VINN Prüfung - Gebäude Übersicht (' + this.building.name + ')');
    });
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  setCurrentTab(currentTab: number) {
    this.currentTab = currentTab;
  }

  onBeforeOpen($event: BeforeOpenEvent) {

  }
}
