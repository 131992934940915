import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AddressLocation} from '../../models/address-location';
import {HttpClient} from '@angular/common/http';
import {Address} from '../../models/address';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) {
  }

  getLocation(address: Address): Observable<AddressLocation> {
    const query = `${address.primary} ${address.city} ${address.country.name}`;
    return this.http.get<AddressLocation>(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`);
  }
}
