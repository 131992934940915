import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {QuaggaJSResultObject} from '@ericblade/quagga2';
import {ScannerComponent} from '../scanner/scanner.component';
import {Company} from '../../models/company';
import {CompanyService} from '../../services/company/company.service';
import {AuthenticationInterface, AuthenticationService} from '../../services/authentication.service';
import {ElectroDeviceInventoryItem} from '../../models/electro-device-inventory-item';
import {ElectroDeviceInventoryItemService} from '../../services/electro-device-inventory-item.service';
import {BuildingService} from '../../services/building/building.service';
import {Building} from '../../models/building';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {environment} from '../../../environments';
import {BeforeOpenEvent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-scanner-search',
  templateUrl: './scanner-search.component.html',
  styleUrls: ['./scanner-search.component.css']
})
export class ScannerSearchComponent implements OnInit, AfterViewInit, AuthenticationInterface {

  @ViewChild(ScannerComponent, {static: true})
  barcodeScanner: ScannerComponent;

  barcodeValue: string;
  companyListLoading = false;
  selectedCompanyId = null;
  companyList: Array<Company> = [];
  displayCamera = false;
  searchStatus = 0;
  device: ElectroDeviceInventoryItem = null;

  buildingList: any;
  buildingCacheList = [];
  selectedBuildingId = null;
  buildingListLoading = false;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  displayImages = false;
  displayDocuments = false;

  importing = false;

  importDocumentationModalOptions = {
    title: 'Import Dokumentation',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
      this.fileUploader.queue = [];
      this.pdfUploader.queue = [];
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => !this.importing,
  };

  constructor(private companyService: CompanyService,
              private authenticationService: AuthenticationService,
              private electroDeviceInventoryItemService: ElectroDeviceInventoryItemService,
              private buildingService: BuildingService) {
  }

  ngOnInit() {
    this.loadCompanyList();
    this.loadBuildingList();

    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }

  ngAfterViewInit(): void {
    this.barcodeScanner.start();
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onValueChanges(result: QuaggaJSResultObject): void {
    this.barcodeValue = result.codeResult.code;
  }

  onStarted(event): void {
  }

  onSearchCompanyChange($event: any) {
    this.selectedCompanyId = $event;
    this.buildingCacheList = this.buildingList.filter((building: Building) => {
      return building.company.id === $event;
    });
  }

  onSearchBuildingChange($event: any) {
    this.selectedBuildingId = $event;
  }

  search() {
    this.searchStatus = 1;
    const barcode = parseInt(this.barcodeValue, 10).toString();
    this.electroDeviceInventoryItemService.get(this.selectedCompanyId, this.selectedBuildingId, barcode).subscribe(resp => {
      this.device = resp.data;
      this.searchStatus = 2;
    });
  }

  loadBuildingList() {
    this.buildingListLoading = true;
    this.buildingService.getNameList().subscribe(resp => {
      this.buildingList = resp.data;
      this.buildingListLoading = false;
    });
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  toggleCamera() {
    this.displayCamera = !this.displayCamera;
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  getImage(vedin: string, image: string) {
    return `${environment.apis.backend}/media/uploads/devices/images/${vedin}/${image}`;
  }

  toggleDisplayDocuments() {
    this.displayDocuments = !this.displayDocuments;
  }

  toggleDisplayImages() {
    this.displayImages = !this.displayImages;
  }


  getReportName(row: any) {
    /*

        const vedin = row.vedin;
        const buildingNumber = this.padLeft(row.building.internalNumber, '0', 2);
        const customerNumber = row.company.customerNumber;
        const barcode = row.barcode;

     */
    return `${environment.apis.backend}/media/uploads/devices/check-reports/${row.fileName}`;
  }

  getReportName2(row: any) {

    return `${environment.apis.backend}/media/uploads/devices/check-reports/${row.fileName}`;
  }

  getReportName3(row: any) {

    return `${environment.apis.backend}/media/uploads/devices/check-reports/${row.fileName}`;
  }

  resetScanner() {
    this.device = null;
    this.searchStatus = 0;
    this.selectedBuildingId = null;
    this.selectedCompanyId = null;
    this.barcodeValue = null;
  }

  documentLink(item) {
    return `${environment.apis.backend}/media/uploads/documents/${this.documentName(item)}`;
  }

  documentName(item) {

    const barcode = this.padLeft(item.barcode, '0', 6);
    return `${item.vedin}_${barcode}_${item.id}.pdf`;
  }

  onBeforeOpen($event: BeforeOpenEvent) {

  }

}
