import {Component, OnInit} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ElectroDeviceService} from '../../services/electro-device.service';
import {ElectroDevice} from '../../models/electro-device';

@Component({
  selector: 'app-electro-device-document-pdf',
  templateUrl: './electro-device-document-pdf.component.html',
  styleUrls: ['./electro-device-document-pdf.component.css']
})
export class ElectroDeviceDocumentPdfComponent implements OnInit {

  pdfImporter = {
    fileNames: Array<string>()
  };

  selectedObject = null;

  importing = false;

  optionPdfInput: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Application_Pdf],
    disableMultipart: false
  };
  totalUploading = 0;

  constructor(public pdfUploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private electroDeviceService: ElectroDeviceService) {
  }

  ngOnInit() {

    this.electroDeviceService.getCurrentElectroDevice.subscribe((object: ElectroDevice) => {
      if (object) {
        this.selectedObject = object;
      }
    });

    this.pdfImporter.fileNames = new Array<string>();

    this.pdfUploader.queue = [];
    this.pdfUploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.pdfUploader.onDropError$.subscribe(
      (err) => {
      });

    this.pdfUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.pdfUploader.onSuccess$.subscribe(
      (data: any) => {
        if (this.pdfUploader.queue.length > 0) {
          this.importing = false;
          this.selectedObject.inventorySummaryFile = `document-${this.selectedObject.id}.pdf`;
        }

        this.selectedObject.inventorySummaryFile = `document-${this.selectedObject.id}.pdf`;
      }
    );
  }

  uploadPdfFiles(item: FileItem) {

    this.importing = true;

    item.upload({
      method: 'POST',
      url: this.electroDeviceService.uploadDocumentPdfUrl(this.selectedObject.id)
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.pdfUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  removePDFDocuments() {
    this.pdfImporter.fileNames = new Array<string>();
    this.pdfUploader.queue = [];
  }

  uploadAll() {
    const that = this;
    this.importing = true;
    this.totalUploading = this.pdfUploader.queue.length;
    this.pdfUploader.queue.forEach(function (value) {
      that.uploadPdfFiles(value);
    });
  }
}
