import {ElectroDeviceManufacturer} from './electro-device-manufacturer';
import {ElectroDeviceType} from './electro-device-type';

export class Inventory {
  id: number;
  createdAt?: string;
  vedin?: string;
  barcode?: string;
  customerNumber?: number;
  buildingId?: number;
  companyId?: number;
  buildingInternalNumber?: number;
  deviceType?: ElectroDeviceType;
  deviceManufacturer?: ElectroDeviceManufacturer;
  deviceModel?: string;
  serialNumber?: string;
  deliveryDate?: string;
  location?: string;
  purchasePrice?: string;
  currency?: string;
  warranty?: string;
  warrantyExpirationDate?: string;
  isVDEChecked?: number;
  energyClass?: string;
  isDangerousDevice?: number;
  eprelNumber?: string;
  existent?: string; // Verloren / Verkauft / Verschrottet
  fileName?: string; // For the Import only;
}
