import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from '../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../../models/page';
import {AuthenticationService} from '../../../../services/authentication.service';
import {ElectroDeviceType} from '../../../../models/electro-device-type';
import {ElectroDeviceTypeService} from '../../../../services/electro-device-type.service';
import {ToastrService} from 'ngx-toastr';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';
import {Company} from '../../../../models/company';
import {DataListResponse} from '../../../../models/data-list-response';
import {ElectroDeviceTestingDevice} from '../../../../models/electro-device-testing-device';

@Component({
  selector: 'app-list-electro-device-type',
  templateUrl: './list-electro-device-type.component.html',
  styleUrls: ['./list-electro-device-type.component.css']
})
export class ListElectroDeviceTypeComponent implements OnInit {

  @ViewChild(DatatableComponent, {static: false}) dataTable: DatatableComponent;

  temp = [];
  createWaiting = false;
  editWaiting = false;
  newObject = new ElectroDeviceType();
  selectedObject = new ElectroDeviceType();

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Name', prop: 'name'},
    ],
    rowList: new Array<ElectroDeviceType>()
  };

  createModalOptions = {
    title: 'Gerätekategorie hinzufügen',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Erstellen',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.create(resolve);
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  editModalOptions = {
    title: 'Gerätekategorie bearbeiten',
    cancelButtonColor: '#111',
    showCancelButton: true,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Speichern',
    showLoaderOnConfirm: true,
    focusCancel: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        this.editWaiting = true;
        this.update(resolve);
      });
    },
    allowOutsideClick: () => !this.editWaiting
  };



  constructor(private electroDeviceTypeService: ElectroDeviceTypeService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.getPage(0);
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.electroDeviceTypeService
      .create(this.newObject)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.createWaiting = false;

          this.displayMessage(dataResponse);
        },
        error => {
          this.createWaiting = false;
        });
  }

  update(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.electroDeviceTypeService
      .update(this.selectedObject)
      .subscribe(dataResponse => {
          this.getPage(0);
          resolve();
          this.editWaiting = false;
        },
        error => {
          this.editWaiting = false;
        });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {
    if (event.key === 'Enter') {
      const val = event.target.value.toLowerCase();
      this.table.rowList = this.temp.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
    }
  }

  delete(rowList) {

    const that = this;

    rowList.forEach(function (row) {

      that.electroDeviceTypeService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  getPage(pageNumber: any) {

    this.table.loading = true;

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.page.pageNumber = pageNumber;
    this.electroDeviceTypeService.getList(this.table.page).subscribe(dataResponse => {

      this.displayMessage(dataResponse);

      const data = dataResponse.data;

      this.temp = [...data];
      this.table.rowList = data;
      this.table.loading = false;
    });
  }

  private displayMessage(dataResponse: DataListResponse<ElectroDeviceTestingDevice>) {
    if (dataResponse.status === 'error') {
      this.toastr.error(dataResponse.message, 'Error');
    }

    if (dataResponse.page !== null) {
      this.table.page = dataResponse.page;
    }
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null): boolean {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  searchRemote($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.getPage(0);
    }
  }
}
