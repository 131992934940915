import {Injectable} from '@angular/core';
import {DataListResponse} from '../../models/data-list-response';

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserType} from '../../models/user-type';
import {HelperService} from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {

  endPoint = `/user-types`;

  list: Observable<DataListResponse<UserType>> = null;

  constructor(private http: HttpClient) {
  }

  getList(): Observable<DataListResponse<UserType>> {

    if (this.list === null) {
      this.list = this.http.get<DataListResponse<UserType>>(`${HelperService.getAPIUrl(this.endPoint)}`);
    }

    return this.list;
  }
}
