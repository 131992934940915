import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {User} from '../../../models/user';
import {Country} from '../../../models/country';
import {CountryService} from '../../../services/country/country.service';
import {ToastrService} from 'ngx-toastr';
import {PermissionMapService} from '../../../services/role/permission-map.service';
import {UserType} from '../../../models/user-type';
import {UserTypeService} from '../../../services/user-type/user-type.service';
import {Locale} from '../../../models/locale';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {AuthenticationService} from '../../../services/authentication.service';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  submitEnabled = true;

  user: User = {
    id: null,
    email: null,
    username: null,
    roleList: null,
    keycloakId: null,
    password: null,
    temporaryPassword: true,
    profile: {
      id: null,
      address: {
        id: null
      },
    },
    createdAt: null,
    updatedAt: null,
    locale: 'de',
    enabled: true,
    emailVerified: true,
    type: null,
    buildingList: [],
    permissionList: [],
  };

  localeList = new Array<Locale>();

  countryList = new Array<Country>();
  countryListLoading: boolean;

  userTypeList = new Array<UserType>();
  userTypeListLoading: boolean;

  dropdownEnabled = true;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 500
  });

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[0];

  buildingPermissions: TreeviewItem[];
  userPermissions: TreeviewItem[];

  companyList = new Array<Company>();
  companyListLoading: boolean;

  constructor(private userService: UserService,
              private countryService: CountryService,
              private toastr: ToastrService,
              private roleService: PermissionMapService,
              private userTypeService: UserTypeService,
              private authenticationService: AuthenticationService,
              private companyService: CompanyService) {
  }

  ngOnInit() {

    this.loadCompanyList();

    this.roleService.getRoles().then(value => {
      this.buildingPermissions = [new TreeviewItem(value.data.buildingList)];
      this.userPermissions = [new TreeviewItem(value.data.permissionList)];
    });

    this.localeList = [
      {
        iso: 'de',
        name: 'Deutsch'
      },
      {
        iso: 'en',
        name: 'English'
      }
    ];

    this.countryService.getNameList().subscribe(response => {
      this.countryList = response.data;
    });

    this.userTypeService.getList().subscribe(response => {
      this.userTypeList = response.data;
    });
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onFormSubmit(value) {

    const that = this;

    this.submitEnabled = false;

    value.buildingList = this.user.buildingList;
    value.permissionList = this.user.permissionList;
    value.username = this.user.email;

    this.userService.create(value).subscribe(resp => {

      if (resp.status === 'success') {
        that.user = {
          id: null,
          email: null,
          username: null,
          roleList: null,
          keycloakId: null,
          password: null,
          temporaryPassword: true,
          profile: {
            id: null,
            address: {
              id: null
            },
          },
          createdAt: null,
          updatedAt: null,
          locale: 'de',
          enabled: true,
          emailVerified: true,
          type: null,
          buildingList: [],
          permissionList: [],
        };

        this.toastr.success('Benutzer wurde erfolgreich erstellt');

      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  onFilterChange(value: string) {

  }

  onBuildingPermissionSelectChange($event: any[]) {
    this.user.buildingList = $event;
  }

  onUserPermissionSelectChange($event: any[]) {
    this.user.permissionList = $event;
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }
}
