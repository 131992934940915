import {Address} from './address';
import {Company} from './company';

export class Building {
  id?: number;
  name: string;
  address: Address;
  company: Company;
  internalNumber?: number;
  createdAt?: string;
  updatedAt?: string;
  companyName?: string;
}
