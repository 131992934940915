import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Page} from '../models/page';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {ElectroDeviceInventory} from '../models/electro-device-inventory';
import {Image} from '../models/image';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceInventoryService {

  endPoint = `/buildings`;

  private currentInventory: BehaviorSubject<ElectroDeviceInventory>;

  constructor(private http: HttpClient) {
    this.currentInventory = new BehaviorSubject<ElectroDeviceInventory>(null);
  }

  get getCurrentInventory(): BehaviorSubject<ElectroDeviceInventory> {
    return this.currentInventory;
  }

  registerCurrentInventory(electroDeviceInventory: ElectroDeviceInventory) {
    this.currentInventory.next(electroDeviceInventory);
  }

  getList(buildingId: string, page: Page, inventoryType: number): Observable<DataListResponse<ElectroDeviceInventory>> {
    return this.http.get<DataListResponse<ElectroDeviceInventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventories`,
      {
        params: new HttpParams()
          .set('inventoryType', inventoryType.toString())
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
          .set('searchCriteria', page.searchCriteria)
      }
    );
  }

  get(buildingId, electroDeviceInventoryId): Observable<DataResponse<ElectroDeviceInventory>> {
    return this.http.get<DataResponse<ElectroDeviceInventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventories/${electroDeviceInventoryId}`
    );
  }

  create(electroDeviceInventory: ElectroDeviceInventory, buildingId: string) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventories`, electroDeviceInventory, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(electroDeviceInventory: ElectroDeviceInventory, buildingId: string): Observable<DataListResponse<ElectroDeviceInventory>> {
    return this.http.put<DataListResponse<ElectroDeviceInventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventories/${electroDeviceInventory.id}`,
      electroDeviceInventory, {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      });
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceInventory>> {
    return this.http.delete<DataListResponse<ElectroDeviceInventory>>(`${HelperService.getAPIUrl('/electro-device-inventories')}/${id}`);
  }

  uploadUrl(buildingId: string) {
    return `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventory/upload`;
  }

  uploadPdfUrl(buildingId: string) {
    return `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventory/pdf/upload`;
  }

  uploadDocumentationUrl(buildingId: number, upload: any) {

    let params = `customerNumber=${upload.customerNumber}&buildingNumber=${upload.buildingNumber}&barcode=${upload.barcode}&documentDate=${upload.documentDate}&`;

    params += `invoice=${upload.isTypeInvoice}&contract=${upload.isTypeContract}&service=${upload.isTypeService}&maintenance=${upload.isTypeMaintenance}&document=${upload.isTypeDocument}&manual=${upload.isTypeManual}&repair=${upload.isTypeRepair}`;

    return `${HelperService.getAPIUrl(this.endPoint)}/${buildingId}/electro-device-inventory/documentation/upload?  ${params}`;
  }


  uploadSummaryPdfUrl(id: number) {
    return `${HelperService.getAPIUrl('/electro-device-inventories')}/${id}/summary-pdf/upload`;
  }

  uploadDefectSummaryPdfUrl(id: number) {
    return `${HelperService.getAPIUrl('/electro-device-inventories')}/${id}/defect-summary-pdf/upload`;
  }

  uploadInventorySummaryPdfUrl(id: number) {
    return `${HelperService.getAPIUrl('/electro-device-inventories')}/${id}/inventory-summary-pdf/upload`;
  }

  uploadImageUrl(id: string) {
    return `${HelperService.getAPIUrl('')}/electro-device-inventories/${id}/images/upload`;
  }

  deleteImage(itemId: string, image: Image) {
    const url = `${HelperService.getAPIUrl('')}/${itemId}/images/${image.id}`;
    return this.http.delete<DataListResponse<Image>>(url);
  }
}
