import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {ElectroDeviceTestingDevice} from '../models/electro-device-testing-device';
import {Page} from '../models/page';
import {DataResponse} from '../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceTestingDeviceService {

  endPoint = `/electro-device-testing-devices`;



  constructor(private http: HttpClient) {
  }


  getList(page: Page): Observable<DataListResponse<ElectroDeviceTestingDevice>> {
    return this.http.get<DataListResponse<ElectroDeviceTestingDevice>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
    });
  }

  get(id): Observable<DataResponse<ElectroDeviceTestingDevice>> {
    return this.http.get<DataResponse<ElectroDeviceTestingDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(value: ElectroDeviceTestingDevice): Observable<DataListResponse<ElectroDeviceTestingDevice>> {
    return this.http.post<DataListResponse<ElectroDeviceTestingDevice>>(HelperService.getAPIUrl(this.endPoint), value);
  }

  update(value: ElectroDeviceTestingDevice): Observable<DataListResponse<ElectroDeviceTestingDevice>> {
    return this.http.put<DataListResponse<ElectroDeviceTestingDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/${value.id}`, value);
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceTestingDevice>> {
    return this.http.delete<DataListResponse<ElectroDeviceTestingDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
