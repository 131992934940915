import {ElectroDeviceInventoryItem} from './electro-device-inventory-item';
import {Building} from './building';

export class ElectroDeviceInventory {
  id: number;
  Items?: ElectroDeviceInventoryItem[];
  testResult?: string;
  testResultDate?: string;
  product?: string;
  fileName?: string;
  totalItems?: number;
  testNorm?: string;
  images: [];
  inventoryType?: number;
  building?: Building;
}
