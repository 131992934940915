import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {TimeZone} from '../../models/time-zone';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  endPoint = `/time-zones`;

  list: Observable<DataListResponse<TimeZone>> = null;

  constructor(private http: HttpClient) {
  }

  getList(): Observable<DataListResponse<TimeZone>> {

    if (this.list === null) {
      this.list = this.http.get<DataListResponse<TimeZone>>(`${HelperService.getAPIUrl(this.endPoint)}`);
    }

    return this.list;
  }
}
