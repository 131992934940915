import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {Building} from '../../models/building';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  languages: string[] = [
    'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG',
    'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI',
    'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH',
    'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ',
    'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT',
    'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS',
    'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU',
    'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG',
    'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG',
    'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK',
    'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU',
    'WF', 'WS', 'YE', 'YT', 'ZA', 'ZM', 'ZW'
  ];

  endPoint = `/buildings`;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<Building>> {
    return this.http.get<DataListResponse<Building>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
        .set('size', page.size.toString())
    });
  }

  get(id): Observable<DataResponse<Building>> {
    return this.http.get<DataResponse<Building>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(building: Building): Observable<DataListResponse<Building>> {
    return this.http.post<DataListResponse<Building>>(HelperService.getAPIUrl(this.endPoint), building);
  }

  update(id, value: any): Observable<DataListResponse<Building>> {
    return this.http.put<DataListResponse<Building>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`, value);
  }

  delete(id: number): Observable<DataListResponse<Building>> {
    return this.http.delete<DataListResponse<Building>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  validateBusinessId(event) {

    const splitted = event.target.value.toLowerCase().split('-');

    // Country
    if (splitted[0].length !== 2 || !this.languages.some((item) => item === splitted[0].toUpperCase())) {
      return `(${splitted[0]}) is not a valid ISO 3166-1 alpha-2 country code.`;
    }

    // City
    if (splitted.length > 1 && (splitted[1].length !== 3)) {
      return 'City formatting is not valid. Use the short form of the City Name using 3 characters';
    }

    // Management group
    if (splitted.length > 2 && (splitted[2].length !== 3 && splitted[2].length !== 4)) {
      return 'Management Group formatting is not valid. It should contain 3-4 characters.';
    }

    // Floor
    if (splitted.length > 3 && (splitted[3].length !== 4 && splitted[3].length !== 5)) {
      return 'Building formatting is not valid. Building should be 5 characters.';
    }

    // Floor
    if (splitted.length > 4 && (splitted[4].length !== 4 || (!splitted[4].startsWith('gf') && !splitted[4].startsWith('f')
      && !splitted[4].startsWith('b')))) {
      return 'Floor formatting is not valid. Floor should starts with (GF, F or B) and can be max. 4 characters.';
    }

    // Network Device
    if (splitted.length > 5 && (splitted[5].length < 2 || splitted[5].length > 6)) {
      return 'Network device formatting is not valid. It can be 2 to 4+2 characters.';
    }

    // IDF/MDF location
    if (splitted.length > 6 && (splitted[6].length < 1 || splitted[6].length > 6)) {
      return 'IDF/MDF location formatting is not valid. Its only allowed 1 to 6 characters.';
    }

    return null;
  }

  getNameList(): Observable<DataListResponse<Building>> {
    return this.http.get<DataListResponse<Building>>(`${HelperService.getAPIUrl(this.endPoint)}/query/list`, {});
  }

  searchTable() {

  }
}
