import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ElectroDeviceTestingItem} from '../models/electro-device-testing-item';
import {DataListResponse} from '../models/data-list-response';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {Page} from '../models/page';
import {ReportSearchModel} from '../models/report-search-model';
import {ElectroDeviceTesting} from '../models/electro-device-testing';
import {ElectroDeviceInventoryItem} from '../models/electro-device-inventory-item';
import {FileItem} from '@wkoza/ngx-upload';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceTestingItemService {

  private selectedTestingItem: BehaviorSubject<ElectroDeviceTestingItem> = null;


  endPoint = `/electro-device-testings`;

  constructor(private http: HttpClient) {
  }

  public getSelectedElectroInventoryItem(): Observable<ElectroDeviceTestingItem> {
    return this.selectedTestingItem.asObservable();
  }

  public setSelectedElectroInventoryItem(testingItem: ElectroDeviceTestingItem): void {

    if (!this.selectedTestingItem) {
      this.selectedTestingItem = new BehaviorSubject<ElectroDeviceTestingItem>(testingItem);
    }

    this.selectedTestingItem.next(testingItem);
  }

  delete(itemId: number): Observable<DataListResponse<ElectroDeviceTestingItem>> {
    return this.http.delete<DataListResponse<ElectroDeviceTestingItem>>(
      `${HelperService.getAPIUrl(`/electro-device-testing-items/${itemId}`)}`,
    );
  }

  get(companyId, barcode: string): Observable<DataResponse<ElectroDeviceTestingItem>> {
    return this.http.get<DataResponse<ElectroDeviceTestingItem>>(
      `${HelperService.getAPIUrl(`/electro-device-testing-items/barcode-search`)}`,
      {
        params: new HttpParams()
          .set('barcode', barcode)
          .set('companyId', companyId)
      }
    );
  }

  getList(testingId: number, page: Page): Observable<DataListResponse<ElectroDeviceTestingItem>> {
    return this.http.get<DataListResponse<ElectroDeviceTestingItem>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${testingId}/items`,
      {
        params: new HttpParams()
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
          .set('searchCriteria', page.searchCriteria)
      }
    );
  }

  search(searchModel: ReportSearchModel, page: Page): Observable<DataListResponse<ElectroDeviceTestingItem>> {

    return this.http.get<DataListResponse<ElectroDeviceTestingItem>>(
      `${HelperService.getAPIUrl('')}/electro-device-testing-items/search`,
      {
        params: new HttpParams()
          .set('searchModel', JSON.stringify(searchModel))
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  getSearchInfoList(): Observable<any> {
    return this.http.get<any>(`${HelperService.getAPIUrl('')}/electro-device-testing-items/search-info`, {
      params: new HttpParams()
    });
  }

  create(testing: ElectroDeviceTesting, testingItem: ElectroDeviceTestingItem) {
console.dir('create called');
    console.dir(testingItem);
    const resultItem = testingItem;
    resultItem.deviceType = testingItem.deviceType; // oder sadece deviceType

    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/${testing.id}/items`, resultItem, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  barcodeSearch(selectedTesting: ElectroDeviceTesting, selectedTestingItem: ElectroDeviceTestingItem): Observable<DataResponse<ElectroDeviceTestingItem>> {
    return this.http.get<DataResponse<ElectroDeviceTestingItem>>(
      `${HelperService.getAPIUrl(`/electro-device-testings/${selectedTesting.id}/barcode-search`)}`,
      {
        params: new HttpParams()
          .set('barcode', selectedTestingItem.barcode)
      }
    );
  }

  update(testing: ElectroDeviceTesting, testingItem: ElectroDeviceTestingItem) {
    return this.http.put(`${HelperService.getAPIUrl(`/electro-device-testing-items/${testingItem.id}`)}`, testingItem, {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: new HttpParams()
        .set('testingItem', JSON.stringify(testingItem))
    });
  }

  uploadImageUrl(id: number) {
    return `${HelperService.getAPIUrl('')}/electro-device-testing-items/images/upload`;

  }

  uploadImage(item: FileItem, deviceTestingItem: ElectroDeviceTestingItem): Observable<DataResponse<any>> {
    const formData = new FormData();

    if (!item || !deviceTestingItem) {
      return;
    }

    // Append the file
    formData.append('file', item.file);  // Assuming item.file contains the actual file

    // Append other form data
    formData.append('itemId', deviceTestingItem.id.toString());
    formData.append('barcode', deviceTestingItem.barcode);

    // Send the POST request using HttpClient
    return this.http.post<DataResponse<any>>(this.uploadImageUrl(deviceTestingItem.id), formData);
  }

  export(electroDeviceTesting: ElectroDeviceTesting): Observable<DataResponse<any>> {
    return this.http.post<DataResponse<any>>(
      `${HelperService.getAPIUrl('')}/electro-device-testings/${electroDeviceTesting.id}/export.csv`,
      {
        params: new HttpParams()
          .set('electroDeviceTesting', JSON.stringify(electroDeviceTesting))
      }
    );
  }

  barcodeCheck(selectedTesting: ElectroDeviceTesting, barcode: string): Observable<DataResponse<ElectroDeviceTestingItem>> {
    return this.http.get<DataResponse<ElectroDeviceTestingItem>>(
      `${HelperService.getAPIUrl(`/electro-device-testings/${selectedTesting.id}/barcode-check`)}`,
      {
        params: new HttpParams().set('barcode', barcode)
      }
    );
  }

  checkDeviceTestingItem(selectedTesting: ElectroDeviceTesting, item: ElectroDeviceInventoryItem): Observable<DataResponse<any>> {
    return this.http.post<DataResponse<any>>(
      HelperService.getAPIUrl(`/electro-device-testings/${selectedTesting.id}/item-check`),
      item,
      {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        params: new HttpParams()
          .set('item', JSON.stringify(item))
      });
  }
}
