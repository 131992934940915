import {Injectable} from '@angular/core';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Country} from '../../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  endPoint = `/countries`;

  list: Observable<DataListResponse<Country>> = null;

  constructor(private http: HttpClient) {
  }

  getNameList(): Observable<DataListResponse<Country>> {

    if (this.list === null) {
      this.list = this.http.get<DataListResponse<Country>>(`${HelperService.getAPIUrl(this.endPoint)}`);
    }

    return this.list;
  }
}
