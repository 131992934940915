import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataResponse} from '../models/data-response';
import {Observable} from 'rxjs';
import {HelperService} from './helper.service';
import {Dashboard} from '../models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  endPoint = `/dashboard`;

  constructor(private http: HttpClient) {
  }

  get(): Observable<DataResponse<Dashboard>> {
    return this.http.get<DataResponse<Dashboard>>(`${HelperService.getAPIUrl(this.endPoint)}`);
  }
}
