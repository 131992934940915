import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {ElectroDeviceManufacturer} from '../models/electro-device-manufacturer';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {Page} from '../models/page';


@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceManufacturerService {

  endPoint = `/electro-device-manufacturers`;

  list: Observable<DataListResponse<ElectroDeviceManufacturer>> = null;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<ElectroDeviceManufacturer>> {
    return this.http.get<DataListResponse<ElectroDeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
    });
  }

  getNameList(): Observable<DataListResponse<ElectroDeviceManufacturer>> {
    return this.http.get<DataListResponse<ElectroDeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/names`, {
      params: new HttpParams()
    });
  }

  get(id): Observable<DataResponse<ElectroDeviceManufacturer>> {
    return this.http.get<DataResponse<ElectroDeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(value: ElectroDeviceManufacturer): Observable<DataListResponse<ElectroDeviceManufacturer>> {
    return this.http.post<DataListResponse<ElectroDeviceManufacturer>>(HelperService.getAPIUrl(this.endPoint), value);
  }

  update(value: ElectroDeviceManufacturer): Observable<DataListResponse<ElectroDeviceManufacturer>> {
    return this.http.put<DataListResponse<ElectroDeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/${value.id}`, value);
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceManufacturer>> {
    return this.http.delete<DataListResponse<ElectroDeviceManufacturer>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
