import {Component, OnInit} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ElectroDeviceInventoryService} from '../../services/electro-device-inventory.service';
import {ElectroDeviceInventory} from '../../models/electro-device-inventory';

@Component({
  selector: 'app-inventory-pdf',
  templateUrl: './inventory-pdf.component.html',
  styleUrls: ['./inventory-pdf.component.css']
})
export class InventoryPdfComponent implements OnInit {

  pdfImporter = {
    fileNames: Array<string>()
  };

  selectedInventory = null;

  importing = false;

  optionPdfInput: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Application_Pdf],
    disableMultipart: false
  };
  totalUploading = 0;

  constructor(public pdfUploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private electroDeviceInventoryService: ElectroDeviceInventoryService) {
  }

  ngOnInit() {

    this.electroDeviceInventoryService.getCurrentInventory.subscribe((inventory: ElectroDeviceInventory) => {
      if (inventory) {
        this.selectedInventory = inventory;
      }
    });

    this.pdfImporter.fileNames = new Array<string>();

    this.pdfUploader.queue = [];
    this.pdfUploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.pdfUploader.onDropError$.subscribe(
      (err) => {
      });

    this.pdfUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.pdfUploader.onSuccess$.subscribe(
      (data: any) => {
        if (this.pdfUploader.queue.length > 0) {
          this.importing = false;
          this.selectedInventory.inventoryFile = `inventory-${this.selectedInventory.id}.pdf`;

        }
        this.selectedInventory.inventoryFile = `inventory-${this.selectedInventory.id}.pdf`;
      }
    );
  }

  uploadPdfFiles(item: FileItem) {

    this.importing = true;

    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryService.uploadSummaryPdfUrl(this.selectedInventory.id)
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.pdfUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.pdfUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  removePDFDocuments() {
    this.pdfImporter.fileNames = new Array<string>();
    this.pdfUploader.queue = [];
  }

  uploadAll() {
    const that = this;
    this.importing = true;
    this.totalUploading = this.pdfUploader.queue.length;
    this.pdfUploader.queue.forEach(function (value) {
      that.uploadPdfFiles(value);
    });
  }
}
