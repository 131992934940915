import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../models/page';
import {User} from '../../../models/user';
import {UserService} from '../../../services/user/user.service';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../services/authentication.service';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent implements OnInit, OnDestroy {

  @ViewChild('userTable', {static: false}) tableDC: DatatableComponent;

  temp = [];

  table: Table = {
    columnMode: ColumnMode.standard,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Benutzername / E-Mail', prop: 'username', width: 180},
      {name: 'Vorname', prop: 'firstName', width: 100},
      {name: 'Nachname', prop: 'lastName', width: 100}
    ],
    rowList: new Array<User>()
  };

  constructor(private userService: UserService,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
    this.table.page.pageNumber = 0;
  }

  ngOnInit(): void {
    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  getPage(pageNumber) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }
    this.table.page.size = 100;
    this.table.page.pageNumber = pageNumber;
    this.table.loading = true;
    this.userService.getList(this.table.page).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.table.page = dataResponse.page;
      }

      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;

      this.temp = this.table.rowList;
    });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  deleteData(row) {

  }

  getDataUrl(id: number) {
    return `${this.userService.endPoint}/${id}`;
  }

  getEditUrl(id: number) {
    return `${this.userService.endPoint}/${id}/edit`;
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList) {
    this.delete(rowList);
  }

  delete(rowList: Array<{ id: string }>) {

    const that = this;

    rowList.forEach(function (row) {
      that.userService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });
    });
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  searchRemote($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.getPage(0);
    }
  }

  updateFilter(event) {

    if (event.key === 'Enter') {
      const val = event.target.value.toLowerCase();

      this.table.rowList = this.temp.filter(function (d) {
        return d.firstName.toLowerCase().indexOf(val) !== -1 ||
          d.username.toLowerCase().indexOf(val) !== -1 ||
          d.email.toLowerCase().indexOf(val) !== -1 || !val;
      });

      this.tableDC.offset = 0;
    }
  }

  protected readonly columnMode = ColumnMode;
}

