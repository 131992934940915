import {KeycloakConfig} from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
  url: 'https://auth.myvinn.io/auth',
  realm: 'portal',
  clientId: 'vinn-pruefung',
  credentials: {
    secret: '53884cc8-2a53-4962-b4c5-0792c50da746'
  }
};

export const environment = {
  production: true,
  assets: {},
  apis: {
    'backend': 'https://api.myvinn.io'
  },
  keycloakConfig
};
