import {Component, OnInit} from '@angular/core';
import {Building} from '../../../models/building';
import {BuildingService} from '../../../services/building/building.service';
import {Company} from '../../../models/company';
import {CompanyService} from '../../../services/company/company.service';
import {CountryService} from '../../../services/country/country.service';
import {Country} from '../../../models/country';
import {Currency} from '../../../models/currency';
import {CurrencyService} from '../../../services/currency/currency.service';
import {TimeZoneService} from '../../../services/time-zone/time-zone.service';
import {LocationService} from '../../../services/location/location.service';
import {TimeZone} from '../../../models/time-zone';
import {first} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-edit-building',
  templateUrl: './edit-building.component.html',
  styleUrls: ['./edit-building.component.css']
})
export class EditBuildingComponent implements OnInit {

  submitEnabled = true;

  building: Building = {
    name: null,
    address: {},
    company: {
      name: null,
      address: {}
    }
  };

  companyList = new Array<Company>();
  countryList = new Array<Country>();
  currencyList = new Array<Currency>();
  timeZoneList = new Array<TimeZone>();

  companyListLoading: boolean;
  countryListLoading: boolean;
  currencyListLoading: boolean;
  timeZoneListLoading: boolean;

  constructor(private buildingService: BuildingService,
              private companyService: CompanyService,
              private countryService: CountryService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private currencyService: CurrencyService,
              private timeZoneService: TimeZoneService,
              private locationService: LocationService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.loadCompanyList();

    this.countryService.getNameList().pipe(first()).subscribe(resp => {
      this.countryList = resp.data;
      if (resp.status === 'error') {
        this.toastr.error(resp.message);
      }
    });

    this.currencyService.getList().pipe(first()).subscribe(resp => {
      this.currencyList = resp.data;
      if (resp.status === 'error') {
        this.toastr.error(resp.message);
      }
    });

    this.timeZoneService.getList().pipe(first()).subscribe(resp => {
      this.timeZoneList = resp.data;
      if (resp.status === 'error') {
        this.toastr.error(resp.message);
      }
    });

    this.buildingService.get(this.route.snapshot.paramMap.get('id')).pipe(first()).subscribe(resp => {

      this.building = resp.data;
      if (resp.status === 'error') {
        this.toastr.error(resp.message);
      }
    });
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
      if (resp.status === 'error') {
        this.toastr.error(resp.message);
      }
    });
  }

  onFormSubmit(building) {

    this.locationService.getLocation(this.building.address).subscribe(resp => {
      const location = resp[0];
      if (location) {
        this.building.address.longitude = location.lon;
        this.building.address.latitude = location.lat;
      }

      this.createBuilding(building);
    });

    this.submitEnabled = false;
  }

  customSearchFn(term: string, item: Company) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  createBuilding(building) {
    this.buildingService.update(this.route.snapshot.paramMap.get('id'), building).subscribe(resp => {
      if (resp.status === 'success') {
        this.toastr.success('Building has been successfully updated!');
      } else if (resp.status === 'error') {
        this.toastr.error(resp.message);
      } else {
        this.toastr.show(resp.message);
      }

      this.submitEnabled = true;
    });
  }

  isGranted(role: string, roleType?: string, rolePermissionType?: number) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }
}
