import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {ElectroDevice} from '../models/electro-device';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {Page} from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceService {

  endPoint = `/electro-devices`;

  private currentElectroDevice: BehaviorSubject<ElectroDevice>;

  constructor(private http: HttpClient) {
    this.currentElectroDevice = new BehaviorSubject<ElectroDevice>(null);
  }

  get getCurrentElectroDevice(): BehaviorSubject<ElectroDevice> {
    return this.currentElectroDevice;
  }

  registerCurrentElectroDevice(electroDevice: ElectroDevice) {
    this.currentElectroDevice.next(electroDevice);
  }

  getList(page: Page): Observable<DataListResponse<ElectroDevice>> {
    return this.http.get<DataListResponse<ElectroDevice>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
        .set('size', page.size.toString())
    });
  }

  get(id): Observable<DataResponse<ElectroDevice>> {
    return this.http.get<DataResponse<ElectroDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(electroDevice: ElectroDevice) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}`, electroDevice, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  update(id, value: any): Observable<DataListResponse<ElectroDevice>> {
    return this.http.put<DataListResponse<ElectroDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`, value);
  }

  delete(id: number): Observable<DataListResponse<ElectroDevice>> {
    return this.http.delete<DataListResponse<ElectroDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  uploadUrl() {
    return `${HelperService.getAPIUrl(this.endPoint)}/upload`;
  }

  getFilteredList(page: Page, searchModel: {
    deviceType: number;
    dangerious: number;
    deviceModel: string;
    deviceManufacturer: number;
    energyClass: number
  }) {
    return this.http.get<DataListResponse<ElectroDevice>>(`${HelperService.getAPIUrl(this.endPoint)}/filtered`, {
      params: new HttpParams()
        .set('deviceModel', searchModel.deviceModel)
        .set('deviceType', searchModel.deviceType.toString())
        .set('dangerious', (searchModel.dangerious).toString())
        .set('deviceManufacturer', searchModel.deviceManufacturer.toString())
        .set('energyClass', searchModel.energyClass.toString())
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('size', page.size.toString()),
    });
  }

  createOne(electroDevice: ElectroDevice) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/single`, electroDevice, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  updateOne(electroDevice: ElectroDevice) {
    return this.http.put(`${HelperService.getAPIUrl(this.endPoint)}/${electroDevice.id}`, electroDevice, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  uploadImageUrl(id: string) {
    return `${HelperService.getAPIUrl('')}/electro-devices/${id}/avatar`;
  }

  uploadDocumentPdfUrl(id: string) {
    return `${HelperService.getAPIUrl('/electro-devices')}/${id}/document-pdf/upload`;
  }
}
