import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if ([401 /* 403*/].indexOf(err.status) !== -1) {
        this.authenticationService.logout();
      } else if (err.status === 403) {
        // this.toastr.error('Access Denied!\n');
      } else if (err.status === 500) {
        this.toastr.error(err.statusText);
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
