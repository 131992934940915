import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataListResponse} from '../../models/data-list-response';
import {HelperService} from '../helper.service';
import {Currency} from '../../models/currency';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  endPoint = `/currencies`;

  list: Observable<DataListResponse<Currency>> = null;

  constructor(private http: HttpClient) {
  }

  getList(): Observable<DataListResponse<Currency>> {

    if (this.list === null) {
      this.list = this.http.get<DataListResponse<Currency>>(`${HelperService.getAPIUrl(this.endPoint)}`);
    }

    return this.list;
  }
}
