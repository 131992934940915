import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataResponse} from '../models/data-response';
import {HelperService} from './helper.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DataListResponse} from '../models/data-list-response';
import {Page} from '../models/page';
import {Inventory} from '../models/inventory';
import {InventorySearchModel} from '../models/inventory-search-model';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  endPoint = `/inventories`;

  constructor(private http: HttpClient) {
  }

  delete(id: number): Observable<DataListResponse<Inventory>> {
    return this.http.delete<DataListResponse<Inventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/${id}`
    );
  }

  getList(buildingId: number, page: Page): Observable<DataListResponse<Inventory>> {
    return this.http.get<DataListResponse<Inventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}`,
      {
        params: new HttpParams()
          .set('buildingId', buildingId.toString())
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
          .set('searchCriteria', page.searchCriteria)
      }
    );
  }

  getInventoryList(buildingId: number, page: Page): Observable<DataListResponse<Inventory>> {
    return this.http.get<DataListResponse<Inventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/devices`,
      {
        params: new HttpParams()
          .set('buildingId', buildingId.toString())
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
          .set('searchCriteria', page.searchCriteria)
      }
    );
  }


  search(searchModel: InventorySearchModel, page: Page): Observable<DataListResponse<Inventory>> {

    return this.http.get<DataListResponse<Inventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/search`,
      {
        params: new HttpParams()
          .set('searchModel', JSON.stringify(searchModel))
          .set('pageNumber', page.pageNumber.toString())
          .set('sortOrder', page.sortOrder)
          .set('sortColumn', page.sortColumn)
      }
    );
  }

  getSearchInfoList(): Observable<any> {
    return this.http.get<any>(`${HelperService.getAPIUrl(this.endPoint)}/search-info`, {
      params: new HttpParams()
    });
  }

  export(): Observable<DataResponse<any>> {
    return this.http.get<DataResponse<any>>(
      `${HelperService.getAPIUrl(this.endPoint)}/export`,
    );
  }

  uploadUrl() {
    return `${HelperService.getAPIUrl(this.endPoint)}/upload`;
  }

  create(inventory: Inventory) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}`, inventory, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  createOne(inventory: Inventory) {
    return this.http.post(`${HelperService.getAPIUrl(this.endPoint)}/single`, inventory, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  updateOne(inventory: Inventory) {


    return this.http.put(`${HelperService.getAPIUrl(this.endPoint)}/${inventory.id}`, inventory, {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    });
  }

  deleteByBuilding(buildingId: number): Observable<DataListResponse<Inventory>> {
    return this.http.delete<DataListResponse<Inventory>>(
      `${HelperService.getAPIUrl(this.endPoint)}/by-building/${buildingId}`
    );
  }
}

